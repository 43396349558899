import React, { Component } from "react"

import SearchBoxRender from "./render"

class SearchBox extends Component {
  state = {}

  UNSAFE_componentWillMount() {}

  render() {
    return <SearchBoxRender {...this.props} {...this.state} />
  }
}

export default SearchBox
