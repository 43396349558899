/* global google */
// allows you to use google, google.map...

import React, { Component } from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import "./index.css"

import MapSearch from "./MapSearch"

import { DEFAULT_ZOOM, DEFAULT_CENTER, ICON_SIZE, MAP_STYLES } from "./const"

import Assets from "../../const/Assets"

const hasPosition = ({ position }) => position && position.lat && position.lng

const MarkerIcons = {
  visible: Assets.IMG_MARKER_PLACE_VISIBLE,
  hidden: Assets.IMG_MARKER_PLACE_HIDDEN,
  selected: Assets.IMG_MARKER_PLACE_SELECTED,
}

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&v=3.exp&libraries=places`,
    loadingElement: <div style={{ height: `100%` }} />,
    // containerElement: <div style={{ height: `450px` }} />, // rewrite in render() from props
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    markers,
    onMapMounted,
    onZoomChanged,
    onCenterChanged,
    center,
    zoom,
    defaultZoom,
    defaultCenter,
    selectedMarker,
    userLocation,
    userId,
    showSearch,
    showPositionEditor = true,
    searchBounds,
    searchValue,
    searchMarker,
    placeMarker,
    onSearchMarkerChanged,
    onBoundsChanged,
    goToPlaceId,
    searchPlaceholder,
    useReplaceMode,
    ...props
  }) => {
    return (
      <GoogleMap
        mapId="72245e7fd834dbc3"
        ref={(ref) => {
          // this.map = ref
          onMapMounted(ref)
        }}
        onZoomChanged={onZoomChanged}
        onCenterChanged={onCenterChanged}
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          // mapId: "72245e7fd834dbc3",
          styles: MAP_STYLES,
        }}
        {...{
          defaultZoom,
          defaultCenter,
          zoom,
          center,
          onBoundsChanged,
        }}
      >
        {showSearch && (
          <MapSearch
            {...{
              onSearchMarkerChanged,
              searchBounds,
              goToPlaceId,
              userId,
            }}
            useReplaceMode={useReplaceMode}
            placeholder={searchPlaceholder}
            selectedMarker={selectedMarker}
            placeMarker={placeMarker}
          />
        )}
        {userLocation && (
          <Marker
            key="userLocation"
            position={userLocation}
            title="Me"
            icon={{
              url: Assets.IMG_MARKER_USER,
              scaledSize: new google.maps.Size(64, 64),
              anchor: new google.maps.Point(32, 32),
            }}
            clickable={false}
            zIndex={999}
          />
        )}
        {!searchValue &&
          markers &&
          markers.filter(hasPosition).map((marker) => {
            const { key, position, name, isPublic } = marker
            const isSelected =
              selectedMarker != null && marker != null && selectedMarker.key === marker.key
            const iconStatus = isSelected ? "selected" : isPublic ? "visible" : "hidden"
            const icon = MarkerIcons[iconStatus]
            const size = ICON_SIZE.default
            return (
              <Marker
                key={key}
                position={position}
                title={name}
                zIndex={isSelected ? 99 : 0}
                icon={{
                  url: icon,
                  scaledSize: new google.maps.Size(size, size),
                }}
                onClick={() => props.onMarkerClick(marker)}
              />
            )
          })}
      </GoogleMap>
    )
  }
)

class Map extends Component {
  static defaultProps = {
    markers: [],
  }

  state = {}

  constructor(props) {
    super(props)
    this.onZoomChanged.bind(this)
    this.onCenterChanged.bind(this)
  }

  componentDidMount() {
    const checkForHeightChange = () => {
      if (this.containerDiv) {
        const { offsetHeight } = this.containerDiv
        if (this.offsetHeight !== offsetHeight) {
          this.offsetHeight = offsetHeight
          this.setState({ availableHeight: offsetHeight })
        }
      }
    }
    checkForHeightChange()
    this.intervalId = setInterval(checkForHeightChange, 10)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  handleMarkerClick = (selectedMarker) => {
    const { onMarkerClick } = this.props
    if (onMarkerClick) onMarkerClick(selectedMarker)
  }

  onMapMounted = (map) => {
    this.map = map
  }

  onSearchMarkerChanged = (searchMarker) => {
    this.setState({ searchMarker })
  }

  onBoundsChanged = () => {
    if (this.map) {
      this.setState({
        searchBounds: this.map.getBounds(),
        // center: refs.map.getCenter(),
      })
    } else {
      this.setState({ searchBounds: null })
      console.warn("onBoundsChanged: WARNING: map not mounted")
    }
  }

  setBoundsToMarkers = (markers) => {
    if (this.map) {
      const bounds = new google.maps.LatLngBounds()
      markers.filter(hasPosition).forEach((marker) => {
        bounds.extend(marker.position)
      })
      this.map.fitBounds(bounds)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { markers: markersDirty, setBoundsToMarkers } = this.props
    const markers = markersDirty.filter(hasPosition)
    if (setBoundsToMarkers) {
      if (!markers || markers.length === 0) {
        this.stringMarkerKeys = null
      } else {
        const stringMarkerKeys = markers.map((x) => x.key).join("|")
        if (this.stringMarkerKeys !== stringMarkerKeys) {
          this.stringMarkerKeys = stringMarkerKeys
          this.setBoundsToMarkers(markers)
        }
      }
    }
  }

  onZoomChanged() {
    if (this.map && this.zoom !== this.map.getZoom()) {
      this.props.onZoomChanged(this.map.getZoom())
    }
  }

  onCenterChanged() {
    if (this.map && this.center !== this.map.getCenter()) {
      this.props.onCenterChanged(this.map.getCenter())
    }
  }

  render() {
    const {
      zoom: zoomProps,
      center: centerProps,
      height: heightProps,
      markers: markersDirty,
      marker,
      user,
      userLocation,
      showSearch,
      goToPlaceId,
      className,
      searchPlaceholder,
      useReplaceMode,
      style,
    } = this.props
    const markers = markersDirty.filter(hasPosition)
    const { availableHeight, searchBounds, searchMarker } = this.state
    const userId = user && user.uid
    const height = heightProps ? heightProps : availableHeight
    let zoom = zoomProps
    let center = centerProps
    const selectedMarker = searchMarker || marker
    if (selectedMarker != null && selectedMarker.position != null) {
      zoom = 15
      center = selectedMarker.position
      if (this.map) {
        if (this.selectedMarkerKey !== selectedMarker.key) {
          this.selectedMarkerKey = selectedMarker.key
          const { lat, lng } = selectedMarker.position
          if (lat && lng) {
            this.map.panTo(new google.maps.LatLng(lat, lng))
          }
        }
      }
    }
    const containerElement = (
      <div
        style={{
          flex: 1,
          height,
        }}
      />
    )
    const styleHeight = heightProps ? { height } : { flex: 1 }
    this.center = center
    this.zoom = zoom
    return (
      <div
        ref={(ref) => (this.containerDiv = ref)}
        style={{
          ...style,
          ...styleHeight,
          ...{
            display: "flex",
            backgroundColor: "#f6f6f6",
            alignItems: "stretch",
            justifyContent: "stretch",
            overflow: "hidden",
          },
        }}
        className={className}
      >
        <MyMapComponent
          {...{
            containerElement,
            zoom,
            center,
            userLocation,
            userId,
            searchBounds,
            markers,
            selectedMarker,
            showSearch,
            defaultZoom: DEFAULT_ZOOM,
            defaultCenter: DEFAULT_CENTER,
            goToPlaceId,
            searchPlaceholder,
            useReplaceMode,
          }}
          placeMarker={marker} // different than searchMarker
          onZoomChanged={this.onZoomChanged}
          onCenterChanged={this.onCenterChanged}
          onSearchMarkerChanged={this.onSearchMarkerChanged}
          onMarkerClick={this.handleMarkerClick}
          onMapMounted={this.onMapMounted}
          onBoundsChanged={this.onBoundsChanged}
        />
      </div>
    )
  }
}

Map.defaultProps = {
  showSearch: false,
  zoom: DEFAULT_ZOOM,
  center: DEFAULT_CENTER,
}

export default Map
