import React, { Component } from "react"
import { database } from "../../firebaseApp"
import BusinessOwnerMultiSelectorRender from "./render"

class BusinessOwnerMultiSelector extends Component {
  state = {}

  componentDidMount() {
    this.updateValue(this.props)
    this.setRefs(this.props.placeId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { placeId: prevPlaceId, placeOwners: prevPlaceOwners } = this.props
    const { placeId, placeOwners } = nextProps
    const prevPlaceOwnersLength = (prevPlaceOwners && prevPlaceOwners.length) || 0
    const placeOwnersLength = (placeOwners && placeOwners.length) || 0
    if (prevPlaceId !== placeId || prevPlaceOwnersLength !== placeOwnersLength) {
      this.updateValue(nextProps)
      if (prevPlaceId !== placeId) this.setRefs(placeId)
    }
  }

  setRefs = (placeId) => {
    this.ownersRef = placeId && database.ref(`places/zecelarece/${placeId}/owners`)
  }

  updateValue = (props) => {
    const { placeOwners } = props
    const value = []
    for (let key in placeOwners) {
      const owner = placeOwners[key]
      const { name, email } = owner
      value.push({ value: key, label: `${name} (${email})` })
    }
    this.setState({ value })
  }

  onChange = (value) => {
    this.setState({ value })
  }

  onAddToSelection = (option, user) => {
    const { uid, displayName: name, email } = user
    if (this.ownersRef && email) {
      this.ownersRef.child(uid).set({ name, email })
    }
  }

  onRemoveFromSelection = (option, user) => {
    const { uid } = user
    if (this.ownersRef) this.ownersRef.child(uid).remove()
  }

  render() {
    return (
      <BusinessOwnerMultiSelectorRender
        {...this.state}
        {...this.props}
        {...{
          onChange: this.onChange,
          onAddToSelection: this.onAddToSelection,
          onRemoveFromSelection: this.onRemoveFromSelection,
        }}
      />
    )
  }
}

BusinessOwnerMultiSelector.defaultProps = {}

export default BusinessOwnerMultiSelector
