import React from "react"
import TagList from "../TagList"

const ArticleTagList = ({ tags, ...props }) => {
  const tagList = tags
    ? Object.keys(tags)
        .map((key) => tags[key])
        .filter((x) => !!x)
        .map((tag) => ({
          ...tag,
          selected: false,
        }))
    : []
  return tagList && tagList.length > 0 ? <TagList tags={tagList} {...props} /> : null
}

export default ArticleTagList
