import React from "react"

import Colors from "../../const/Colors"

import Layout from "../primitives/Layout"
import Image from "../primitives/Image"
import Text from "../primitives/Text"

const ImageAvatar = ({ imageSize, imageUrl, userName, ...props }) => {
  let firstLetter = userName && userName[0]
  return (
    // <Layout centerVertical center style={{ width: 'auto' }}> ?? check native
    <Layout
      center
      centerVertical
      marginHalf
      style={[
        styles.layoutCircle,
        {
          width: imageSize,
          height: imageSize,
          borderRadius: imageSize / 2,
        },
      ]}
    >
      {imageUrl ? (
        <Image
          imageUrl={imageUrl}
          style={{
            width: imageSize,
            height: imageSize,
          }}
        />
      ) : (
        <Text big bold center white centerVertical>
          {firstLetter}
        </Text>
      )}
    </Layout>
    // </Layout>
  )
}

const styles = {
  layoutCircle: {
    borderColor: "transparent",
    borderWidth: 0,
    borderStyle: "solid",
    backgroundColor: Colors.accent,
    overflow: "hidden",
  },
}

export default ImageAvatar
