import React from "react"
import "./index.css"
import Colors from "../../../../const/Colors"

const SwitchCheckbox = ({ value, onValueChange, ...props }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={(e) => onValueChange(e.target.checked)} />
      <span className="slider round" style={{
        backgroundColor: value ? Colors.accent : Colors.disabledBackground
      }}></span>
    </label>
  )
}

export default SwitchCheckbox
