const styles = {
  container: {
    display: "flex",
    flexGrow: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  facebookLike: {
    marginTop: 16,
    marginBottom: 16,
  },
}

export default styles
