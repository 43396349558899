import React from "react"
import {FilePicker} from "react-file-picker"
import Loading from "../Loading"
import Button from "../Button"
import {PADDING} from "../../../const/Dimensions"

const EditableImage = ({
                         // onPress,
                         fullScreen,
                         imageUrl,
                         onSelectFile,
                         editable,
                         isLoading,
                         style,
                         className: classNameProps,
                         defaultImage,
                         onPressDeleteFile,
                         ...props
                       }) => {
  let className = classNameProps
  if (editable) className += " clickable"
  const Image = (
    <img {...props} className={className} style={style} src={imageUrl || defaultImage}/>
  )

  if (isLoading)
    return (
      <Loading className={classNameProps} spinner show={true}>
        {Image}
      </Loading>
    )

  if (editable)
    return (
      <div style={{display: 'flex'}}>
        <FilePicker
          style={style}
          extensions={["png", "jpg", "jpeg"]}
          maxSize={4}
          onChange={onSelectFile}
          onError={console.error}
        >
          {Image}
        </FilePicker>
        {imageUrl ? (
          <Button black onPress={onPressDeleteFile} style={{marginLeft: PADDING, width: 120}}>
            {`Delete Icon`}
          </Button>
        ) : null}
      </div>
    )

  return Image
}

export default EditableImage
