export const FirebaseConfig = {
  zecelarece: {
    prod: {
      apiKey: "AIzaSyAthf14Lv-AFqYa0fgU3tQBxME_tABsOWY",
      authDomain: "zecelarece-places.firebaseapp.com",
      databaseURL: "https://zecelarece-places.firebaseio.com",
      projectId: "zecelarece-places",
      storageBucket: "zecelarece-places.appspot.com",
      messagingSenderId: "312266499316",
      appId: "1:312266499316:ios:b425c89fcda28f9f",
    },
  },
  paris: {
    prod: {
      apiKey: "AIzaSyDBLnLyXqSOJOJGL_7cwWt4TPOEBpXoLkY",
      authDomain: "paris-map-a969d.firebaseapp.com",
      databaseURL: "https://paris-map-a969d-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "paris-map-a969d",
      storageBucket: "paris-map-a969d.appspot.com",
      messagingSenderId: "406548321584",
      appId: "1:406548321584:web:765855f3253a198d40e4f5",
      measurementId: "G-HDEN6G9M0H",
    },
  },
}
