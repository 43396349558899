import React, { Component } from "react"

import ArticleFinderRender from "./render"

const getDefaultSuggestions = (props) => {
  return props.alwaysRenderSuggestions ? getSuggestions("", props.suggestions) : []
}

class ArticleFinder extends Component {
  state = {
    value: "",
    suggestions: getDefaultSuggestions(this.props),
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.suggestions),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: getDefaultSuggestions(this.props),
    })
  }

  onSuggestionSelected = (event, { suggestion: article }) => {
    // if (this.props.onSelectedArticle)
    //   this.props.onSelectedArticle(article)
    this.setState({ value: "", suggestions: [] })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.filterKey !== nextProps.filterKey) this.setState({ value: "" })
  }

  render() {
    const { value, suggestions } = this.state
    const { title, description, alwaysRenderSuggestions, renderSuggestion } = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      value,
      onChange: this.onChange,
    }

    return (
      <ArticleFinderRender
        {...this.state}
        layoutProps={this.props}
        autosuggestProps={{
          title,
          description,
          inputProps,
          getSuggestionValue,
          alwaysRenderSuggestions,
          renderSuggestion,
          onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
          onSuggestionsClearRequested: this.onSuggestionsClearRequested,
          shouldRenderSuggestions: () => true,
          onSuggestionSelected: this.onSuggestionSelected,
          suggestions: value && value !== "" ? suggestions : this.props.suggestions,
        }}
      />
    )
  }
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, articles) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  return inputLength === 0
    ? articles
    : articles
        .filter((x) => {
          // Search if the name matches
          return (x.name || "").toLowerCase().indexOf(inputValue) !== -1
        })
        .sort((x) => (x.name.toLowerCase().startsWith(inputValue) ? -1 : 1))
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name

ArticleFinder.defaultProps = {
  alwaysRenderSuggestions: false,
}

export default ArticleFinder
