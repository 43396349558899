import React from "react"
import PropTypes from "prop-types"

import ImageCover from "../primitives/ImageCover"
import isMobile from "../primitives/isMobile"

const ImageCoverUploaderRender = ({
  cover,
  coverUrl,
  coverUrlTemp,
  fileRefUrl,
  refUrl,
  imageSize,
  ...props
}) => {
  const imageUrl = (cover && (cover[imageSize] ? cover[imageSize] : cover.original)) || null
  const author = cover && cover.author
  //return null
  return <ImageCover imageUrl={coverUrlTemp ? coverUrlTemp : imageUrl} {...props} author={author} />
}

ImageCoverUploaderRender.propTypes = {
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  cover: PropTypes.object,
  coverUrlTemp: PropTypes.string,
  imageSize: PropTypes.string,
}

ImageCover.defaultProps = {
  imageSize: "big",
}

export default isMobile(ImageCoverUploaderRender)
