import React, { Component } from "react"
import { database } from "../../firebaseApp"
import PlaceMultiSelectorRender from "./render"

class PlaceMultiSelector extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.updateValue(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedTag: prevTag } = this.props
    const { selectedTag: tag } = nextProps
    const prevTagId = prevTag && prevTag.id
    const tagId = tag && tag.id
    if (prevTagId !== tagId) {
      this.updateValue(nextProps)
    }
  }

  updateValue = (props) => {
    const { places, selectedTag: tag } = props
    const tagId = tag && tag.id
    const value = []
    const showByTag = tagId != null
    for (const key in places) {
      if (places.hasOwnProperty(key)) {
        const { name, tags } = places[key]
        if (showByTag && tags && tags[tagId]) value.push({ value: key, label: name }) //  className: 'react-select-selected-item' => classname not working values..
      }
    }
    this.setState({ value })
  }

  onChange = (value) => {
    this.setState({ value })
  }

  onRemoveFromSelection = (option) => {
    const { ref } = this.getRefFromState(option.value)
    if (ref) ref.remove()
  }

  onAddToSelection = (option) => {
    const { ref, value } = this.getRefFromState(option.value)
    if (ref) ref.set(value)
  }

  getRefFromState = (placeId) => {
    const { selectedTag } = this.props
    if (selectedTag) {
      const { id, name } = selectedTag
      const ref = database.ref(`places/zecelarece/${placeId}/tags/${id}`)
      return { ref, value: { id, name } }
    }
    console.error("ERROR: can't edit place without a selectedTag")
    return null
  }

  render() {
    const { places } = this.props
    const options = []
    for (const key in places) {
      if (places.hasOwnProperty(key)) {
        const { name } = places[key]
        options.push({ value: key, label: name, className: "react-select-list-item" })
      }
    }
    // options.sort((a, b) => a.label.localeCompare(b.label))
    return (
      <PlaceMultiSelectorRender
        {...this.state}
        {...this.props}
        onChange={this.onChange}
        onRemoveFromSelection={this.onRemoveFromSelection}
        onAddToSelection={this.onAddToSelection}
        options={options}
      />
    )
  }
}

PlaceMultiSelector.defaultProps = {}

export default PlaceMultiSelector
