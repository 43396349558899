import React from "react"

import SectionTitle from "../SectionTitle"
import UserMultiSelector from "../UserMultiSelector"
import Layout from "../primitives/Layout"

const BusinessOwnerMultiSelectorRender = ({
  value,
  onChange,
  onAddToSelection,
  onRemoveFromSelection,
}) => (
  <Layout>
    <SectionTitle>Select business owners</SectionTitle>
    <UserMultiSelector
      paddingHalf
      name="form-field-users"
      {...{
        value,
        onChange,
        onAddToSelection,
        onRemoveFromSelection,
      }}
    />
  </Layout>
)

BusinessOwnerMultiSelectorRender.defaultProps = {}

export default BusinessOwnerMultiSelectorRender
