import React, { Component } from "react"
import Radium from "radium"
import TextareaAutosize from "react-autosize-textarea"
import { buildClassNameText, buildStylesText } from "./utils"
import Button from "../Button"
import Layout from "../Layout"
import Colors from "../../../const/Colors"
// Separate local imports from dependencies
import "./index.css"

class Text extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      value: props.children,
    }
  }

  // all this will go in index.js when we implement it on mobile
  onTextEdited = () => {
    this.setState({ editing: false })
    const { onTextEdited } = this.props
    if (onTextEdited) onTextEdited(this.state.value)
    else
      console.warn(
        "asking for text change but onTextEdited is not implemented. Text changed value: ",
        this.state.value
      )
  }

  onTextChanged = (value) => {
    const { onTextEdited } = this.props
    if (onTextEdited) onTextEdited(value)
    else
      console.warn(
        "asking for text change but onTextEdited is not implemented. Text changed value: ",
        this.state.value
      )
  }

  onCancelEditing = () => {
    const { onCancelEditing } = this.props
    this.cancelEditing()
    if (onCancelEditing) onCancelEditing()
  }

  cancelEditing = () => {
    const { children } = this.props
    this.setState({ editing: false, value: children })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // cancel the edit if the current edited object changed before getting saved
    // editKey represents the current object
    // could not use 'key' props cause each key needs to be unique
    // And here we don't really need this uniqueness, but mostly to react to the relevant change
    const { editKey, children, mustValidateEdit = true } = nextProps
    if (this.editKey !== editKey) {
      this.editKey = editKey
      this.setState({ value: children, editing: false })
    } else if (!mustValidateEdit && children !== nextState.value) {
      this.setState({ value: children })
    }
  }

  render() {
    const { singleLine } = this.props // don't extract

    const {
      children,
      editable,
      mustValidateEdit = true,
      style: parentStylesArray,
      placeholder,
      htmlTag,
      onPress,
      onTextEdited,
      ...props
    } = this.props // extract

    const { editing, value } = this.state

    const { className, props: propsAfterClassname } = buildClassNameText(props)
    const { styles: textStyles, props: propsAfterStyles } = buildStylesText(propsAfterClassname)

    let styles = { ...textStyles }
    parentStylesArray.forEach((style) => {
      styles = { ...styles, ...style }
    })

    // build editable styles checking the previous styles
    // because of the border hack needing to set the margins at -1px
    // it overrides the margins we might have set
    // This is why this might seem complicated, but... it works
    const editableStyles = buildEditableStyles(editable, styles)
    styles = { ...styles, ...editableStyles }

    let onClick
    if (editable) {
      onClick = (e) => this.setState({ editing: true, value: children })
      if (!mustValidateEdit || editing) {
        return (
          <Layout>
            <TextareaAutosize
              innerRef={(ref) => ref && ref.focus()}
              style={styles}
              className={`text-input ${className ? className : ""}`}
              rows={1}
              maxRows={singleLine ? 1 : undefined}
              value={value}
              placeholder={placeholder}
              onChange={(e) => {
                this.setState({ value: e.target.value })
                if (!mustValidateEdit) {
                  this.onTextChanged(e.target.value)
                }
              }}
            />
            {mustValidateEdit && (
              <Layout horizontal style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Button black fullWidth onPress={this.onCancelEditing} style={{ marginRight: 5 }}>
                  {`Cancel`}
                </Button>
                <Button black fullWidth onPress={this.onTextEdited}>
                  {`Save`}
                </Button>
              </Layout>
            )}
          </Layout>
        )
      }
    }
    let text = children
    if (!text)
      text = editable
        ? placeholder
        : `A contentful text is coming soon here... Come back later and we will have some nice suprise for you.`
    if (editable && !children) {
      styles = { ...styles, ...{ color: Colors.accent } }
    }
    const onClickOrPress = onClick || onPress
    if (onClickOrPress) {
      styles = { ...styles, cursor: "pointer" }
    }
    const TextTag = htmlTag || `p`
    return (
      <TextTag {...propsAfterStyles} onClick={onClickOrPress} style={styles} className={className}>
        {text}
      </TextTag>
    )
  }
}

const buildEditableStyles = (editable, currentStyles) => {
  const { marginBottom: mB, marginTop: mT, marginLeft: mL, marginRight: mR } = currentStyles
  const styles = {}
  if (editable) {
    styles[":hover"] = {
      borderRadius: "7.5px",
      borderWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.33)",
      borderStyle: "dashed",
      marginBottom: mB ? mB - 1 : "-1px",
      marginTop: mT ? mT - 1 : "-1px",
      marginLeft: mL ? mL - 1 : "-1px",
      marginRight: mR ? mR - 1 : "-1px",
    }
  }
  return styles
}

export default Radium(Text)
