import { PADDING, PADDING_HALF } from "../../const/Dimensions"

const styles = {
  layoutHeader: {
    marginBottom: -2,
    borderBottomStyle: "solid",
    borderBottomWidth: PADDING_HALF / 2,
    borderBottomColor: "rgba(0, 0, 0, 0.03)",
  },
  layoutPictureWithTitle: {},
  layoutTitleBar: {},
  layoutTitleContent: {
    flex: 1,
  },
  positionEditor: {
    // position: 'absolute',
    // bottom: -PADDING * 3,
    // left: PADDING,
    // right: 0,
    flex: 0,
    // height: 100,
  },
  leftMenu: {

  },
}
export default styles
