import React, { Component } from "react"

import {
  getBasePathFromProps,
  getQueryParamsValueFromProps,
  buildQueryString,
  DEFAULT_TAG_ID,
  buildNavigationLinkWithQuery,
} from "../utils/PathUtils"

export default function StateManager(WrappedScreen) {
  return class extends Component {
    updateFilteredLists = (selectedTagId) => {
      const { placesList } = this.props
      const m = buildMakers(placesList, selectedTagId)
      this.markers = m.markers
      this.markersList = m.markersList
      this.placesFiltered = m.placesFiltered
      this.placesListFiltered = m.placesListFiltered
    }

    handleSelectedTag = (selectedTag) => {
      // const source =
      const { location, history } = this.props
      const { pathname } = location
      const selectedTagId = (selectedTag && selectedTag.id) || DEFAULT_TAG_ID
      const queryString = buildQueryString({
        tagId: selectedTagId,
      })

      const { source: sourceQuery } = getQueryParamsValueFromProps(this.props)
      const firstPathnameSplit = pathname && pathname.split("/")
      const firstPathname =
        firstPathnameSplit && firstPathnameSplit.length > 1 && firstPathnameSplit[1]
      const isFromPlace = firstPathname === "place"
      const source = sourceQuery || (isFromPlace && "map")
      const newUrl = source ? `/${source}?${queryString}` : `?${queryString}`
      const action = isFromPlace ? history.push : history.replace
      action(newUrl)
    }

    handleMarkerClick = (marker) => {
      if (marker) {
        this.goToPlaceId(marker.key)
      }
    }

    handleClickBackPlaces = () => {
      this.goToPath(buildGoBackFromPlacePath(this.props, true))
    }

    goToPlaceId = (placeId) => {
      this.goToPath(buildGoToPlacePath(this.props, placeId))
    }

    goToPath = (path, isReplace) => {
      const {
        history: { push, replace },
      } = this.props
      if (isReplace) replace(path)
      else push(path)
    }

    render() {
      const { tags, places, pathOnClickBack, location } = this.props
      const url = location && location.pathname + location.search
      const basePath = getBasePathFromProps(this.props)
      const { tagId } = getQueryParamsValueFromProps(this.props)
      const placeId =
        ["place"].indexOf(basePath) !== -1 && location && location.pathname.split("/")[2]
      let selectedTagId = tagId === DEFAULT_TAG_ID ? null : tagId //|| tagIdParams)
      if (!selectedTagId) {
        if (basePath === "tag") selectedTagId = location && location.pathname.split("/")[2]
      }
      const selectedTag = selectedTagId && tags && tags[selectedTagId]
      this.updateFilteredLists(selectedTagId)
      const place = places && placeId && places[placeId]
      const marker = this.markers && placeId && this.markers[placeId]

      if (this.url !== url) {
        this.url = url
        this.pathOnClickBack = pathOnClickBack || "/" // default
        // BUILD BACK CLICK PATH
        if (basePath === "place") {
          // for all path matching this array or home path
          this.pathOnClickBack = buildGoBackFromPlacePath(this.props)
        }
      }

      if (this.selectedTagId !== selectedTagId) {
        this.selectedTagId = selectedTagId
      }
      return (
        <WrappedScreen
          {...this.props}
          {...{ selectedTag, place, marker }}
          placesFiltered={this.placesFiltered}
          placesListFiltered={this.placesListFiltered}
          markers={this.markersList}
          markersMap={this.markers}
          handleSelectedTag={this.handleSelectedTag}
          handleMarkerClick={this.handleMarkerClick}
          handleClickBackPlaces={this.handleClickBackPlaces}
          goToPlaceId={this.goToPlaceId}
          pathOnClickBack={this.pathOnClickBack}
        />
      )
    }
  }
}

const buildMakers = (placesList, selectedTagId) => {
  const markersList = []
  const markers = {}
  const placesFiltered = {}
  const placesListFiltered = []
  if (placesList) {
    const showByTag = selectedTagId != null
    const showAllPlaces = !showByTag
    placesList.forEach((place) => {
      const key = place.id
      const { name, position } = place
      if (showAllPlaces || (showByTag && place.tags && place.tags[selectedTagId])) {
        // build markers
        const isPublic = place.visibility
        const marker = { name, position, key, id: key, isPublic }
        markersList.push(marker)
        markers[key] = marker
        placesListFiltered.push(place)
        placesFiltered[key] = place
      }
    })
  }
  return { markers, markersList, placesFiltered, placesListFiltered }
}

const buildGoToPlacePath = (props, placeId) => {
  return buildGoToMarkerPath(props, "place", placeId)
}

const buildGoToMarkerPath = (props, articleType, placeId) => {
  let basePath = getBasePathFromProps(props)
  if (basePath !== "map" && basePath !== "list") basePath = "map"

  const { tagId, source } = getQueryParamsValueFromProps(props)
  const queryString = buildQueryString({ tagId, source: source || basePath })
  return `/${articleType}/${placeId || ""}?${queryString}`
}

const buildGoBackFromPlacePath = (props, placeId) => {
  return buildNavigationLinkWithQuery(props, "/?source") //`/${source}?${queryString}`
}
