import React, { Component } from "react"
import { auth } from "../../firebaseApp"
import { signOutNative, configureNativeSignIn } from "../../utils/SignInUtils"

import isMobile from "../../components/primitives/isMobile"
import Layout from "../../components/primitives/Layout"
import Text from "../../components/primitives/Text"
import Card from "../../components/primitives/Card"
import Button from "../../components/primitives/Button"
import Login from "../../components/Login"
import ImageAvatar from "../../components/ImageAvatar"

class LoginScreen extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    configureNativeSignIn()
  }

  onSignOut = () => {
    signOutNative((err) => {
      if (err) console.log(err)
      else {
        auth
          .signOut()
          .then(() => {
            console.log("user signed out from firebase!")
          })
          .catch((error) => {
            // Handle Errors here.
            // var errorCode = error.code;
            var errorMessage = error.message
            console.log("errorMessage", errorMessage)
          })
      }
    })
  }

  render() {
    const { user } = this.props
    // console.log(user)
    let photoURL
    let userName
    if (user) {
      userName = user.displayName
      const { providerData } = user
      providerData.forEach((x) => {
        if (x.providerId === "facebook.com") photoURL = x.photoURL
      })
      // if (!photoURL) photoURL = user.photoURL
    }
    return (
      <LoginScreenRender
        {...this.props}
        {...this.state}
        {...{ userName, photoURL }}
        onSignOut={this.onSignOut}
      />
    )
  }
}

const LoginScreenRender = ({ isMobile, isLoading, isLoggedIn, loadingMessage, ...props }) => (
  <Layout style={styles.app} className="content-padded" centerVertical paddingHalf>
    {isLoggedIn ? <SignedInView {...props} /> : <Login {...props} />}
  </Layout>
)

const SignedInView = ({
  onSignOut,
  userName,
  photoURL,
}) => (
  <Layout scrollable style={{ flex: 1, width: "100%" }}>
    <Card padding marginHalf center centerVertical style={{ flex: 1 }}>
      <Text big bold center paddingHalf>
        My profile
      </Text>
      <Text soft bold center paddingHalf>
        {userName}
      </Text>
      <ImageAvatar imageUrl={photoURL} imageSize={80} {...{ userName }} />
      <Button primary margin onPress={onSignOut}>
        Sign-out
      </Button>
    </Card>
  </Layout>
)

const styles = {
  app: {
    textAlign: "left",
    flex: 1,
    paddingBottom: 10, // weird bug on mobile
    width: "100%",
  },
}

export default isMobile(LoginScreen)
