import React from "react"
import Text from "../Text"

const Button = ({ style: parentStylesArray, onPress, enabled, children, darkText, ...props }) => {
  let styles = {
    display: "flex",
  }
  let stylesText = {
    letterSpacing: "0.12em",
  }
  if (parentStylesArray) parentStylesArray.forEach((s) => (styles = { ...styles, ...s }))
  return (
    <button
      className="clickable"
      disabled={!enabled}
      onClick={enabled ? onPress : null}
      style={styles}
      {...props}
    >
      <Text white={!darkText} center bold {...props} style={stylesText}>
        {children}
      </Text>
    </button>
  )
}

export default Button
