import React, { Component } from "react"
import firebase from "../../firebaseApp"
// import GoogleButton from 'react-google-button'

import isMobile from "../../components/primitives/isMobile"
import Loading from "../../components/primitives/Loading"
import Layout from "../../components/primitives/Layout"
import Text from "../../components/primitives/Text"

const firebaseui = typeof window === "undefined" ? null : require("firebaseui")

const uiConfig = {
  callbacks: {
    signInSuccess: function (currentUser, credential, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("fbAccessToken", credential.accessToken)
      }
      return true
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById("loader").style.display = "none"
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      scopes: ["public_profile", "email", "user_events", "manage_pages", "publish_pages"],
    },
  ],
  // Terms of service url.
  // tosUrl: '<your-tos-url>'
}

class Login extends Component {
  state = {}

  componentDidMount() {
    const ui = firebaseui ? new firebaseui.auth.AuthUI(firebase.auth()) : null
    if (ui) {
      ui.start("#firebaseui-auth-container", uiConfig)
    }
  }

  render() {
    return (
      <LoginRender {...this.props} {...this.state} onPressLoginGoogle={this.onPressLoginGoogle} />
    )
  }
}

const LoginRender = ({ isMobile, isLoading, isLoggedIn, loadingMessage, ...props }) => (
  <Layout centerVertical>
    <Text center padding big bold>
      Sign-in
    </Text>
    <div id="firebaseui-auth-container"></div>
    <div id="loader">
      <Loading spinner message={`Signing you in...`} show={true} />
    </div>
  </Layout>
)

export default isMobile(Login)
