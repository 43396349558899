import React from "react"

import styles from "./styles"

import Map from "../../components/Map"
import isMobile from "../../components/primitives/isMobile"
import Layout from "../../components/primitives/Layout"
import Head from "../../components/primitives/Head"

const MapScreenRender = ({
  markers,
  user,
  userLocation,
  zoom, // only for react web
  center, // only for react web
  region, // only for react native
  selectedTag,
  goToPlaceId,
  onMapMounted,
  handleMarkerClick,
  handleZoomChanged, // only for react web
  handleCenterChanged, // only for react web
  handleRegionChange, // only for react native
}) => {
  const article = selectedTag
  return (
    <Layout style={styles.container}>
      {article ? (
        <Head
          title={`Bucharest's ${article.name} Map - Zece La Rece`}
          description={article.description}
          picture={article.cover && (article.cover.big || article.cover.original)}
        />
      ) : null}
      <Map
        ref={onMapMounted}
        height={null}
        marker={null}
        onMarkerClick={handleMarkerClick}
        setBoundsToMarkers={false}
        showSearch
        onZoomChanged={handleZoomChanged}
        onCenterChanged={handleCenterChanged}
        onRegionChange={handleRegionChange}
        {...{ user, goToPlaceId, userLocation, markers, zoom, center, region }}
      />
    </Layout>
  )
}

export default isMobile(MapScreenRender)
