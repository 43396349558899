import React, { Component } from "react"
import { withRouter } from "../../../compat/Router"
import ListView from "../ListView"

class ListViewScrollToTopOnRouteChange extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    if (
      !prevLocation ||
      location.pathname !== prevLocation.pathname ||
      location.search !== prevLocation.search
    ) {
      requestAnimationFrame(() => {
        if (this.listView) {
          this.listView.scrollTo(0)
        }
      })
    }
  }

  onListMounted = (ref) => (this.listView = ref)

  render() {
    return <ListView onListMounted={this.onListMounted} {...this.props} />
  }
}

export default withRouter(ListViewScrollToTopOnRouteChange)
