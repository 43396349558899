import React, { Component } from "react"
import MultiSelectorRender from "./render"

class MultiSelector extends Component {
  state = {}

  onChange = (value) => {
    // console.log('You\'ve selected:', value)
    const { value: prevValue } = this.props
    const addedValues = value.filter((x) => prevValue.indexOf(x) === -1)
    const removedValues = prevValue.filter((x) => value.indexOf(x) === -1)
    addedValues.forEach(this.onCreate)
    removedValues.forEach(this.onRemove)

    // should be implemented to set props
    this.props.onChange(value)

    // this.setState({ value })
  }

  onRemove = (option) => {
    const { onRemoveFromSelection } = this.props
    if (onRemoveFromSelection) onRemoveFromSelection(option)
  }

  onCreate = (option) => {
    const { onAddToSelection } = this.props
    if (onAddToSelection) onAddToSelection(option)
  }

  render() {
    return (
      <MultiSelectorRender
        {...this.state}
        {...this.props}
        onChange={this.onChange}
        // options={options}
      />
    )
  }
}

MultiSelector.defaultProps = {}

export default MultiSelector
