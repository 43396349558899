const brand = process.env.REACT_APP_APP_NAME
const Assets = {
  IMG_BRAND_LOGO: require(`../assets/${brand}/img/logo_small.png`).default,
  IMG_MARKER_PLACE_VISIBLE: require(`../assets/${brand}/img/map/marker-place-visible.png`).default,
  IMG_MARKER_PLACE_HIDDEN: require(`../assets/${brand}/img/map/marker-place-hidden.png`).default,
  IMG_MARKER_PLACE_SELECTED: require(`../assets/${brand}/img/map/marker-place-selected.png`)
    .default,

  IMG_MARKER_USER: require(`../assets/system/img/map/marker-user.png`).default,

  ICON_ADD: require(`../assets/system/img/ic_add_black_24dp_2x.png`).default,
  ICON_BACK: require(`../assets/system/img/ic_arrow_back_black_24dp_2x.png`).default,
  ICON_DONE: require(`../assets/system/img/ic_done_black_24dp_2x.png`).default,
  ICON_LIST: require(`../assets/system/img/ic_list_black_24dp_2x.png`).default,
  ICON_MAP: require(`../assets/system/img/ic_map_black_24dp_2x.png`).default,
  ICON_USER: require(`../assets/system/img/ic_person_outline_black_24dp_2x.png`).default,
  ICON_IMAGE: require(`../assets/system/img/ic_photo_black_24dp_2x.png`).default,
  ICON_PLACE: require(`../assets/system/img/ic_place.png`).default,
  ICON_TEXT: require(`../assets/system/img/ic_subject_black_24dp_2x.png`).default,
  ICON_TAGS: require(`../assets/system/img/ic_tags.png`).default,
  ICON_FACEBOOK: require(`../assets/system/img/facebook_64.png`).default,
  ICON_INSTAGRAM: require(`../assets/system/img/instagram_64.png`).default,

  IMG_ARROW_DOWN: require(`../assets/system/img/map/ic_arrow_down.png`).default,
  IMG_ARROW_UP: require(`../assets/system/img/map/ic_arrow_up.png`).default,
  IMG_ARROW_LEFT: require(`../assets/system/img/map/ic_arrow_left.png`).default,
  IMG_ARROW_RIGHT: require(`../assets/system/img/map/ic_arrow_right.png`).default,
  IMG_CANCEL: require(`../assets/system/img/map/ic_cancel.png`).default,
  IMG_VALIDATE: require(`../assets/system/img/map/ic_validate.png`).default,
}

export default Assets
