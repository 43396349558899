import React, { Component } from "react"

import { database } from "../../firebaseApp"
import { withRouter } from "../../compat/Router"

import PlaceAdminRender from "./render"
import { schedulePlacePostAndSaveToFirebase, cancelScheduledPosts } from "../../facebook/post"

const cleanNumberValue = (value, min, max, defaultValue) => {
  // this makes sense only for textEntry
  if (defaultValue === undefined && !value) return value

  let finalValue = !value && value !== 0 ? defaultValue : value
  if (typeof finalValue === "string") finalValue = finalValue.replace(/[^\d.-]/g, "")
  if (finalValue < min) finalValue = min
  else if (finalValue > max) finalValue = max
  return finalValue
}

const DEFAULT_TEMPERATURE = 1.0
const DEFAULT_FREQUENCE_PENALTY = 0.0
const DEFAULT_PRESENCE_PENALTY = 0.0
const DEFAULT_WORD_COUNT_LIMIT = 150

class PlaceAdmin extends Component {
  state = {
    valueEditors: null,
    gptTemperature: DEFAULT_TEMPERATURE,
    gptFrequencyPenalty: DEFAULT_FREQUENCE_PENALTY,
    gptPresencePenalty: DEFAULT_PRESENCE_PENALTY,
    gptWordCountLimit: DEFAULT_WORD_COUNT_LIMIT,
    gptIncludeGoogleReviews: true,
    gptAdditionalInfo: "",
    gptGeneratedDescription: "",
    googleReviews: null,
    isLoadingGptGeneratedDescription: false,
    hasUsedAiGenerationOnce: false,
  }

  UNSAFE_componentWillMount() {
    const { place } = this.props
    this.setRefs(place)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { place } = nextProps
    this.setRefs(place)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe() {
    this.editorId = null
    if (this.nameRef) this.nameRef.off()
    if (this.placeVisibilityRef) this.placeVisibilityRef.off()
    if (this.placeWebsiteRef) this.placeWebsiteRef.off()
  }

  setRefs(place) {
    if (!place || place.id !== this.placeId) {
      const placeId = place ? place.id : null
      this.placeId = placeId
      this.unsubscribe()
      if (placeId) {
        const placeRefUrl = `places/zecelarece/${placeId}`
        const placeRef = database.ref(placeRefUrl)
        this.editorRef = placeRef.child("editorId")
        this.placeDescriptionRef = placeRef.child("description")
        this.placeHasUsedAiGenerationOnceRef = placeRef.child("hasUsedAiGenerationOnce")
        this.placeVisibilityRef = placeRef.child("visibility")
        this.placeWebsiteRef = placeRef.child("website")
        this.editorRef.on("value", (snap) => {
          const valueEditors = snap.val()
          this.setState({ valueEditors })
        })
        this.placeVisibilityRef.on("value", (snap) => {
          this.setState({ placeVisibility: snap.val() })
        })
        this.placeWebsiteRef.on("value", (snap) => {
          this.setState({ website: snap.val() })
        })
        this.placeHasUsedAiGenerationOnceRef.on("value", (snap) => {
          this.setState({ hasUsedAiGenerationOnce: snap.val() })
        })
      }
    }
  }

  handleChangeEditor = ({ value, label }) => {
    if (this.editorRef) this.editorRef.set(value)
    // this.setState({ valueEditors: value })
  }

  handleChangePlaceVisibility = (value) => {
    if (this.placeVisibilityRef) this.placeVisibilityRef.set(value)
  }

  handleChangeWebsite = (value) => {
    if (this.placeWebsiteRef) this.placeWebsiteRef.set(value)
  }

  handleSchedulePost = () => {
    const { place } = this.props
    this.setState({ isScheduleButtonLoading: true }, () => {
      schedulePlacePostAndSaveToFirebase(
        {
          place,
          // facebookPlaceId:
        },
        () => {
          this.setState({ isScheduleButtonLoading: false })
        }
      )
    })
  }

  handleCancelScheduledPost = () => {
    this.setState({ isScheduleButtonLoading: true }, () => {
      const { scheduledPosts } = this.state
      cancelScheduledPosts(
        {
          articleId: this.placeId,
          articleType: "place",
          scheduledPosts,
        },
        () => {
          this.setState({ isScheduleButtonLoading: false })
        }
      )
    })
  }

  handleChangeGptTemperature = (value) => {
    this.setState({ gptTemperature: cleanNumberValue(value, 0, 2) })
  }

  handleChangeGptFrequencyPenalty = (value) => {
    this.setState({ gptFrequencyPenalty: cleanNumberValue(value, -2, 2) })
  }

  handleChangeGptPresencePenalty = (value) => {
    this.setState({ gptPresencePenalty: cleanNumberValue(value, -2, 2) })
  }

  handleChangeGptWordCountLimit = (value) => {
    this.setState({ gptWordCountLimit: cleanNumberValue(value, 0, 2000) })
  }

  handleChangeGptIncludeGoogleReviews = (gptIncludeGoogleReviews) => {
    this.setState({ gptIncludeGoogleReviews })
  }

  handleChangeGptAdditionalInfo = (gptAdditionalInfo) => {
    this.setState({ gptAdditionalInfo })
  }

  handleChangeGptGeneratedDescription = (gptGeneratedDescription) => {
    this.setState({ gptGeneratedDescription })
  }

  handleGptGenerateArticle = () => {
    this.setState({ isLoadingGptGeneratedDescription: true }, async () => {
      const {
        gptAdditionalInfo,
        gptWordCountLimit,
        gptTemperature,
        gptFrequencyPenalty,
        gptPresencePenalty,
        gptIncludeGoogleReviews,
      } = this.state
      const params = {
        token: process.env.REACT_APP_BULLSHIT_TOKEN,
        placeId: this.placeId,
      }
      if (gptAdditionalInfo) params.gptAdditionalInfo = gptAdditionalInfo
      if (gptTemperature) params.gptTemperature = gptTemperature
      if (gptFrequencyPenalty) params.gptFrequencyPenalty = gptFrequencyPenalty
      if (gptPresencePenalty) params.gptPresencePenalty = gptPresencePenalty
      if (gptWordCountLimit) params.gptWordCountLimit = gptWordCountLimit
      if (gptIncludeGoogleReviews) params.gptIncludeGoogleReviews = gptIncludeGoogleReviews
      const url = `${
        process.env.REACT_APP_API_BASE_URL
      }/places/generate/description?${new URLSearchParams(params)}`
      const res = await fetch(url)
      const json = await res.json()
      const { data } = json || {}
      const { generatedDescription, googleReviews } = data || {}
      this.setState({
        gptGeneratedDescription: generatedDescription,
        googleReviews,
        isLoadingGptGeneratedDescription: false,
      })
    })
  }

  handleGptSaveAndReplaceDescription = () => {
    const { gptGeneratedDescription } = this.state
    if (this.placeDescriptionRef) {
      this.placeDescriptionRef.set(gptGeneratedDescription).then(() => {
        this.placeHasUsedAiGenerationOnceRef.set(true).then(() => {
          this.setState({
            gptGeneratedDescription: "",
            googleReviews: null,
            hasUsedAiGenerationOnce: true,
          })
          if (typeof window !== "undefined" && window && window.scrollTo) window.scrollTo(0, 760)
        })
      })
    }
  }

  render() {
    return (
      <PlaceAdminRender
        {...this.state}
        {...this.props}
        handleChangeEditor={this.handleChangeEditor}
        handleChangeWebsite={this.handleChangeWebsite}
        handleChangePlaceVisibility={this.handleChangePlaceVisibility}
        handleSchedulePost={this.handleSchedulePost}
        handleCancelScheduledPost={this.handleCancelScheduledPost}
        handleChangeGptTemperature={this.handleChangeGptTemperature}
        handleChangeGptFrequencyPenalty={this.handleChangeGptFrequencyPenalty}
        handleChangeGptPresencePenalty={this.handleChangeGptPresencePenalty}
        handleChangeGptWordCountLimit={this.handleChangeGptWordCountLimit}
        handleChangeGptIncludeGoogleReviews={this.handleChangeGptIncludeGoogleReviews}
        handleChangeGptAdditionalInfo={this.handleChangeGptAdditionalInfo}
        handleChangeGptGeneratedDescription={this.handleChangeGptGeneratedDescription}
        handleGptGenerateArticle={this.handleGptGenerateArticle}
        handleGptSaveAndReplaceDescription={this.handleGptSaveAndReplaceDescription}
      />
    )
  }
}

export default withRouter(PlaceAdmin)
