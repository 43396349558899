import React from "react"
import Layout from "../Layout"
import Colors from "../../../const/Colors"

const Card = ({ children, style, backgroundColor, borderColor, borderWidth, ...props }) => {
  return (
    <Layout
      {...props}
      style={{
        ...styles.layoutCard,
        ...{ backgroundColor, borderColor, borderWidth },
        ...style,
      }}
    >
      {children}
    </Layout>
  )
}

const styles = {
  layoutCard: {
    // borderRadius: 7.5,
    // borderStyle: 'solid',
  },
}

Card.defaultProps = {
  backgroundColor: Colors.section,
  borderColor: "transparent",
  borderWidth: 0,
}

export default Card
