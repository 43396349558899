import React, { Component } from "react"
import { Mobile } from "../ScreenTypes"

export default function IsMobile(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <Mobile>
          {(isMobile) => <WrappedComponent {...this.props} isMobile={isMobile} />}
        </Mobile>
      )
    }
  }
}
