import React from "react"
import TagFilter from "./TagFilter"
import Layout from "../primitives/Layout"
import isMobile from "../primitives/isMobile"
import withNativeScreenWidth from "../primitives/withNativeScreenWidth"
class Filter extends React.Component {
  componentDidMount() {
    this.onDidUpdate()
  }

  componentDidUpdate() {
    this.onDidUpdate()
  }

  onDidUpdate = () => {
    const { selectedTag } = this.props
    const selectedTagId = selectedTag && selectedTag.id
    if (this.selectedTagId !== selectedTagId) {
      this.selectedTagId = selectedTagId
      if (selectedTag) {
        const selectedElement = document.getElementById(selectedTag.name)
        if (selectedElement) {
          selectedElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          })
        }
      }
    }
  }

  render() {
    return <FilterRender {...this.props} />
  }
}
const FilterRender = ({
  isMobile,
  tagsList,
  selectedTag,
  onSelectTag,
  nativeScreenWidth,
  ...props
}) => {
  const smallWidth = nativeScreenWidth && nativeScreenWidth < 340

  let selectedTagIndex = 0 //selectedTag ? tagsList.indexOf(selectedTag) + 1 : 0
  tagsList.forEach((x, i) => {
    if (selectedTag && selectedTag.id === x.id) selectedTagIndex = i + 1
  })
  if (selectedTagIndex < 0) selectedTagIndex = 0
  const tagTitles = ["All"].concat(tagsList.map((x) => x.name))
  return (
    <Layout linear center vertical className="filter" {...props}>
      <TagFilter
        key="tags"
        titles={tagTitles}
        {...{ smallWidth }}
        selectedIndex={selectedTagIndex}
        onSelectIndex={(i) => onSelectTag(i === 0 ? null : tagsList[i - 1])}
      />
    </Layout>
  )
}

export default withNativeScreenWidth(isMobile(Filter))
