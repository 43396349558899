/* global google */
// allows you to use google, google.map...

import React from "react"
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox"

const SearchBoxRender = ({
  searchBounds,
  searchValue,
  onSearchValueChanged,
  onSearchBoxMounted,
  onPlacesChanged,
  placeholder,
  ...props
}) => (
  <SearchBox
    ref={onSearchBoxMounted}
    bounds={searchBounds}
    controlPosition={google.maps.ControlPosition.TOP_LEFT}
    onPlacesChanged={onPlacesChanged}
  >
    <div>
      <input
        type="text"
        placeholder={placeholder}
        value={searchValue}
        onChange={onSearchValueChanged}
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          margin: `10px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </div>
  </SearchBox>
)

SearchBoxRender.defaultProps = {
  placeholder: "Search for a place to add"
}

export default SearchBoxRender
