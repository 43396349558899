import { FB } from "fb"
import moment from "moment"
import { database } from "../firebaseApp"
import { PAGE_ID } from "./constants"

export const deleteFacebookPost = (postId) =>
  new Promise((resolve, reject) => {
    if (postId) {
      return getPageAccessToken()
        .then((accessToken) => {
          FB.api(
            `/${postId}`,
            "DELETE",
            {
              access_token: accessToken,
            },
            function (response) {
              // console.log('>>> response', response)
              resolve(response)
            }
          )
        })
        .catch(reject)
    } else {
      return reject(`You forgot to include a postId`)
    }
  })

export const schedulePostOnPage = (link, publishTime, message, placeId) =>
  new Promise((resolve, reject) => {
    if (publishTime) {
      return getPageAccessToken()
        .then((accessToken) => {
          FB.api(
            `/${PAGE_ID}/feed`,
            "POST",
            {
              message,
              scheduled_publish_time: publishTime.toString(),
              published: "false",
              access_token: accessToken,
              link,
              place: placeId, // Page ID of a location associated with this post.
            },
            function (response) {
              if (response && response.id) {
                resolve(response)
              } else {
                reject(response)
              }
            }
          )
        })
        .catch(reject)
    } else {
      return reject(`You forgot to include a publish time`)
    }
  })

export const getPageAccessToken = () =>
  new Promise((resolve, reject) => {
    FB.api("/me/accounts", "GET", {}, function (response) {
      const { data, error } = response
      if (error) {
        reject(error)
      } else {
        if (data && data.length) {
          let accessToken
          data.forEach((account) => {
            if (account && account.id === "1288447657871019") {
              accessToken = account.access_token
            }
          })
          if (accessToken) {
            resolve(accessToken)
          } else {
            reject({ message: "Can't find any account named Zece La Rece" })
          }
        } else {
          reject({ message: "Can't find any account for this user" })
        }
      }
    })
  })

const schedulePostAndSaveToFirebase = (
  { articleId, articleType, publishTime, articleRefUrl, message, facebookPlaceId },
  callback
) => {
  const shareUrl = `https://zecelarece.ro/${articleType}/${articleId}`
  schedulePostOnPage(shareUrl, publishTime, message, facebookPlaceId).then((response) => {
    Promise.all([
      database
        .ref(`scheduledPosts/zecelarece/byObject/${articleId}`)
        .child(response.id)
        .set(publishTime),
      database.ref(`scheduledPosts/zecelarece/byPost/${response.id}`).set({
        articleType: articleType,
        articleId: articleId,
        articleRefUrl,
        publishTime,
        shareUrl,
      }),
    ]).then((values) => {
      if (callback) {
        callback()
      }
    })
  })
}

export const cancelScheduledPosts = ({ articleId, articleType, scheduledPosts }, callback) => {
  if (scheduledPosts && Object.keys(scheduledPosts).length) {
    Object.keys(scheduledPosts).forEach((postId) => {
      deleteFacebookPost(postId).then((response) => {
        let promises = [
          database.ref(`scheduledPosts/zecelarece/byObject/${articleId}`).child(postId).remove(),
          database.ref(`scheduledPosts/zecelarece/byPost/${postId}`).remove(),
        ]
        if (articleType === "place") {
          promises = promises.concat([
            database.ref(`scheduledPosts/zecelarece/placeTimestamps/${articleId}`).remove(),
          ])
        }
        Promise.all(promises).then((values) => {
          if (callback) callback()
        })
      })
    })
  } else {
    if (callback) callback()
  }
}

export const schedulePlacePostAndSaveToFirebase = (
  {
    place,
    // facebookPlaceId
  },
  callback
) => {
  const placePostTimestampsRef = database.ref(`scheduledPosts/zecelarece/placeTimestamps`)
  const oneDay = 3600 * 24
  let publishTime = moment().hours(9).minutes(30).seconds(0).milliseconds(0).unix() + oneDay
  placePostTimestampsRef
    .orderByValue()
    .startAt(publishTime)
    .once("value", (snap) => {
      if (snap.exists) {
        const snapVal = snap.val()
        if (snapVal) {
          const values = Object.keys(snapVal).map((key) => snapVal[key])
          // console.log({ snapVal, values, publishTime })
          while (values.indexOf(publishTime) !== -1) {
            publishTime += oneDay
          }
        }
      }
      const {
        address
      } = place || {}
      const shortAdress = address && address.split(",")[0]
      schedulePostAndSaveToFirebase(
        {
          articleId: place.id,
          articleType: "place",
          message: `🌤️Neata,

        🍦Today we introduce you to ${place.name}, a place located on ${shortAdress}.
        
        ↓ Read more ↓`,
          publishTime,
          articleRefUrl: `places/zecelarece/${place.id}`,
        },
        () => {
          placePostTimestampsRef.child(place.id).set(publishTime).then(callback)
        }
      )
    })
}

export const getScheduledPostsFirebaseRef = (articleId) =>
  database.ref(`scheduledPosts/zecelarece/byObject/${articleId}`)
