import React from "react"
import Autosuggest from "react-autosuggest"
import { withRouter } from "../../compat/Router"

import { buildNavigationLinkWithSource } from "../../utils/PathUtils"

import Colors from "../../const/Colors"

import Text from "../primitives/Text"
import Layout from "../primitives/Layout"
import isMobile from "../primitives/isMobile"

import ArticleFinderRow from "./ArticleFinderRow"

const ArticleFinderRender = ({ isMobile, layoutProps, autosuggestProps, ...props }) => {
  const { inputProps, title, description, alwaysRenderSuggestions } = autosuggestProps
  const { articlePath } = layoutProps
  return (
    <Layout {...layoutProps}>
      {title && (
        <Text padding medium bold accent style={{ paddingBottom: 4 }}>
          {title}
        </Text>
      )}
      {description && (
        <Text padding bold soft style={{ paddingTop: 0 }}>
          {description}
        </Text>
      )}
      <Autosuggest
        id="articleFinder"
        {...autosuggestProps}
        inputProps={{
          ...inputProps,
          placeholder: "Search the list...",
          style: {
            margin: 5,
            padding: 10,
            borderColor: Colors.divider,
            color: Colors.textColor,
          },
        }}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        focusInputOnSuggestionClick={false}
        renderSuggestion={(article) => (
          <ArticleFinderRow
            to={buildNavigationLinkWithSource(props, `/${articlePath}/${article.id}`)}
            key={article.id}
            article={article}
          />
        )}
      />
    </Layout>
  )
}

ArticleFinderRender.defaultProps = {
  renderSuggestion: null,
}

export default withRouter(isMobile(ArticleFinderRender))
