export const injectPlatformSpecificStylesInProps = ({
  // children,
  // className,
  style: parentStylesArray,
  linear,
  relative,
  vertical,
  horizontal,
  wrap,
  staticContext, // to avoid passing it
  ...props
}) => {
  let style = {
    display: "flex",
  }

  if (wrap) {
    // style.flexGrow = 0
    // style.flexShrink = 1
    // style.flexBasis = 'auto'
    style.flexWrap = "wrap"
  }

  parentStylesArray.forEach((s) => {
    style = { ...style, ...s }
  })
  return { ...props, style }
}
