import React, { Component } from "react"

import { database } from "../../firebaseApp"

import PlaceRender from "./render"

class Place extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    const { place } = this.props
    this.setRefs(place)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { place } = nextProps
    this.setRefs(place)
  }

  setRefs(place) {
    if (!place || place.id !== this.placeId) {
      const placeId = place ? place.id : null
      this.placeId = placeId
      if (place) {
        const placeRefUrl = `places/zecelarece/${placeId}`
        const placeFileRefUrl = `image/zecelarece/place_cover/${placeId}.jpg`
        this.setState({
          placeRefUrl,
          placeFileRefUrl,
        })
      } else {
        const { placeRefUrl } = this.state
        if (placeRefUrl) {
          this.setState({ placeRefUrl: null, placeFileRefUrl: null })
        }
      }
    }
  }

  handleDeletePlace = async () => {
    const { place } = this.props
    const placeName = place.name
    const areYouSureText = `Are you sure you want to delete ${placeName}?\nEverything about it will be lost, there's no going back.\n\nPS: It is said that if your name is Luka, destroying useless data brings good luck.`
    const errorMessage = `Something went wrong, let a Gabriel know.`
    const successMessage = `Successful deletion. Goodbye ${placeName}! You'll be missed`
    if (window.confirm(areYouSureText)) {
      try {
        const placeRef = database.ref(this.state.placeRefUrl)
        if (placeRef) {
          await placeRef.remove()
          alert(successMessage)
          this.props.history.replace("/")
        } else {
          alert(errorMessage)
        }
      } catch (err) {
        console.error(err)
        alert(errorMessage)
      }
    }
  }

  render() {
    return (
      <PlaceRender handleDeletePlace={this.handleDeletePlace} {...this.props} {...this.state} />
    )
  }
}

export default Place
