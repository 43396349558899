export const articleHasDescription = (article) => {
  const { description } = article
  return description && !description.startsWith("Lorem ipsum")
}

export const articleIsDone = (article) => {
  const { cover, visibility } = article
  return articleHasDescription(article) && cover && visibility
}

export const articleHasCover = (article) => {
  return article.cover != null
}
