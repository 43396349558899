const styles = {
  containerFlex: {
    display: "flex",
    height: "100vh",
    width: "100%",
  },
  containerUndefined: {
    display: undefined,
    height: "100vh",
    width: "100%",
  },
}

export default styles
