import React, { Component } from "react"
import { database, auth } from "./firebaseApp"
import RouterScreen from "./screens/RouterScreen"
import { withRouter } from "./compat/Router"
import { checkNativeLocationPermission } from "./utils/PermissionUtils"
import { getDistanceBetweenLatLngInMeters } from "./utils/LocationUtils"
import CacheUtils from "./utils/CacheUtils"
import { setFacebookAccessTokenFromLocalStorage } from "./facebook/auth"
import { LOADING_MESSAGE_PLACES, LOADING_MESSAGE_LOCATION } from "./const/Strings"

class App extends Component {
  state = {
    isLoggedIn: false,
    places: undefined,
    tags: undefined,
    admin: undefined,
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.setState({ isLoggedIn: user !== null })
      if (user) {
        setTimeout(() => {
          database.ref(`admins/zecelarece/${user.uid}`).on("value", (snap) => {
            const admin = snap.val()
            if (admin && admin.enabled) {
              this.setRefs()
            }
            this.setState({ admin })
          })
        }, 2000) // important on first creation of account, else this ref is never called
        setFacebookAccessTokenFromLocalStorage()
      } else {
        // CacheUtils.removeItem("statePlaces")
        CacheUtils.removeItem("stateTags")
        this.props.history.replace("/login")
        console.log("User is signed out")
      }
      // reset admin rights until ref gets read
      this.setState({ user }, () => {
        this.setRefs()
      })
    })

    checkNativeLocationPermission((isGranted) => {
      if (isGranted) {
        if (typeof navigator !== "undefined" && navigator && navigator.geolocation) {
          setTimeout(() => {
            // this setTimeOut is needed
            // this is the weirdest fix in the world
            this.watchPositionId = navigator.geolocation.watchPosition(
              this.onPositionChanged,
              this.onPositionPermissionRefused,
              {
                enableHighAccuracy: true,
                maximumAge: 5 * 60e3, // 5mn of cache allowed
                distanceFilter: 10, // 10m precision (apparently?)
                useSignificantChanges: true, // monitor constantly..?
              }
            )
          }, 1)
        } else {
          console.log(`browser doesn't support geolocation`)
        }
      } else {
        this.onPositionPermissionRefused()
      }
    })
  }

  componentWillUnmount() {
    if (
      typeof navigator !== "undefined" &&
      navigator &&
      navigator.geolocation &&
      this.watchPositionId
    ) {
      navigator.geolocation.clearWatch(this.watchPositionId)
      this.watchPositionId = null
    }
  }

  onPositionPermissionRefused = (err) => {
    console.warn(`user did not grant location permission`, { err })
  }

  onPositionChanged = (position) => {
    const {
      coords: { latitude: lat, longitude: lng },
    } = position
    const userLocation = { lat, lng }
    const { places, placesList } = buildPlacesWithDistanceFromUserLocation(
      userLocation,
      this.state.places
    )
    this.setState({ userLocation, places, placesList })
  }

  loadFromCache = (callback) => {
    // const statePlaces = CacheUtils.getState("statePlaces")
    const stateTags = CacheUtils.getState("stateTags")
    let newState = {}
    // if (statePlaces) newState = { ...newState, ...statePlaces }
    if (stateTags) newState = { ...newState, ...stateTags }
    this.setState(newState, callback)
  }

  setRefs = () => {
    this.loadFromCache(() => {
      const tagsRef = database.ref("tags").child("zecelarece").orderByChild("order")
      const placesRef = database.ref("places").child("zecelarece")
      tagsRef.off()
      placesRef.off()
      tagsRef.on("value", this.onTagsUpdate)
      placesRef.on("value", this.onPlacesUpdate)
    })
  }

  onPlacesUpdate = (snap) => {
    const statePlaces = buildPlacesWithDistanceFromUserLocation(this.state.userLocation, snap.val())
    // CacheUtils.saveState("statePlaces", statePlaces)
    this.setState(statePlaces)
  }

  onTagsUpdate = (snap) => {
    const stateTags = buildTagsFromSnap(snap)
    CacheUtils.saveState("stateTags", stateTags)
    this.setState(stateTags)
  }

  goToPlaces = () => {
    this.props.history.push("/map")
  }

  handleClickBackPlaces = (e) => {
    this.props.history.replace("/")
  }

  handleZoomChanged = (zoom) => {
    // console.log('>>> zoom', zoom)
    this.setState({ zoom })
  }

  handleCenterChanged = (center) => {
    // console.log('>>> center', center)
    this.setState({ center })
  }

  handleRegionChange = (region) => {
    // if (this.region) {
    //   console.log('>>> region set state', region)
    this.setState({ region })
    // } else {
    //   console.log('>>> region ignore', region)
    //   this.region = region
    // }
  }

  render() {
    const { places, tags, tagsList } = this.state
    const isPlacesReady = places !== undefined
    const isLoading = !isPlacesReady
    const loadingMessage = isPlacesReady ? LOADING_MESSAGE_LOCATION : LOADING_MESSAGE_PLACES

    if (!isLoading && !this.hasDoneFirstLoading) {
      this.hasDoneFirstLoading = true
    }

    return (
      <RouterScreen
        {...this.state}
        tagsList={tagsList}
        loadingMessage={loadingMessage}
        isLoading={isLoading}
        goToPlaces={this.goToPlaces}
        handleClickBackPlaces={this.handleClickBackPlaces}
        handleZoomChanged={this.handleZoomChanged}
        handleCenterChanged={this.handleCenterChanged}
        handleRegionChange={this.handleRegionChange}
      />
    )
  }
}

const buildTagsFromSnap = (snap) => {
  const tagsVal = snap.val()
  const tags = tagsVal && {
    ...tagsVal,
  }
  const tagsList = []
  snap.forEach((x) => {
    tagsList.push(x.val())
  })
  // for (var key in tags) tagsList.push(tags[key])
  return { tags, tagsList }
}

const sortItemsListByDistance = (itemsList) => {
  itemsList.sort((a, b) => (a.distance && b.distance ? a.distance - b.distance : 0))
}

const buildItemsWithDistanceFromUserLocation = (userLocation, itemsRaw, sortCallback) => {
  if (!itemsRaw) return { items: null, itemsList: null }
  const hasUserLocation = userLocation != null
  const userLat = userLocation && userLocation.lat
  const userLng = userLocation && userLocation.lng
  const items = {}
  const itemsList = []
  for (const key in itemsRaw) {
    const item = itemsRaw[key]
    const { position } = item
    if (position) {
      // correct items object if lat lng stored as String
      const { lat, lng } = position
      position.lat = Number(lat)
      position.lng = Number(lng)
      if (hasUserLocation) {
        item.distance = Math.floor(getDistanceBetweenLatLngInMeters(lat, lng, userLat, userLng))
      }
    }
    item.id = key
    items[key] = item
    itemsList.push(item)
  }
  if (sortCallback) sortCallback(itemsList)
  // sortItemsListByDistance(itemsList)
  return { items, itemsList }
}

const buildPlacesWithDistanceFromUserLocation = (userLocation, placesRaw) => {
  const { items: places, itemsList: placesList } =
    buildItemsWithDistanceFromUserLocation(userLocation, placesRaw, sortItemsListByDistance) || {}
  return { places, placesList }
}

export default withRouter(App)
