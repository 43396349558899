import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "react-select/dist/react-select.css"
import "./react-select.css"
import "react-image-gallery/styles/css/image-gallery.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"

// import ScrollToTopOnRouteChange from './components/primitives/ScrollToTopOnRouteChange'
//import registerServiceWorker from './registerServiceWorker';

// isConnected not supported in web, always online
const initialProps = { isConnected: true, platform: "web", state: window.__initialState }
let scriptTag = document.getElementById("initial-state")
if (scriptTag) scriptTag.outerHTML = ""
delete window.__initialState

if (typeof Array.isArray === "undefined") {
  Array.isArray = function (obj) {
    return Object.prototype.toString.call(obj) === "[object Array]"
  }
}

ReactDOM.render(
  <BrowserRouter>
    <App {...initialProps} />
  </BrowserRouter>,
  document.getElementById("root")
)
//registerServiceWorker();
