import React from "react"

import Layout from "../primitives/Layout"
import isMobile from "../primitives/isMobile"
import MultiSelector from "../primitives/MultiSelector"

const UserMultiSelectorRender = ({
  value,
  options,
  onChange,
  onAddToSelection,
  onRemoveFromSelection,
}) => (
  <Layout>
    <MultiSelector
      name="form-field-users"
      {...{
        value,
        options,
        onChange,
        onAddToSelection,
        onRemoveFromSelection,
      }}
    />
  </Layout>
)

UserMultiSelectorRender.defaultProps = {}

export default isMobile(UserMultiSelectorRender)
