import React, { Component } from "react"

import PlaceListItemRender from "./render"

class PlaceListItem extends Component {
  render() {
    return <PlaceListItemRender {...this.props} {...this.state} />
  }
}

export default PlaceListItem
