import React from "react"
import { Switch, Route, Redirect } from "../../compat/Router"

import styles from "./styles"

import AdminPlaceListScreen from "../AdminPlaceListScreen"
import AdminTagListScreen from "../AdminTagListScreen"
import AdminTagScreen from "../AdminTagScreen"
import AdminTagAddScreen from "../AdminTagAddScreen"
import LoginScreen from "../LoginScreen"
import ListScreen from "../ListScreen"
import MapScreen from "../MapScreen"
import PlaceScreen from "../PlaceScreen"
import PrivacyPolicyScreen from "../PrivacyPolicyScreen"

import HeaderRouter from "../../components/HeaderRouter"
import Head from "../../components/primitives/Head"
import isMobile from "../../components/primitives/isMobile"
import Loading from "../../components/primitives/Loading"
import SafeAreaView from "../../components/primitives/SafeAreaView"
import ScrollToTopOnRouteChange from "../../components/primitives/ScrollToTopOnRouteChange"

import Assets from "../../const/Assets"

const PATH_MAP_VIEW = "/map"
const PATH_LIST_VIEW = "/list"
const PATH_PLACE_VIEW = "/place/:placeId"
const PATH_LOGIN = "/login"
const PATH_PROFILE = "/profile"
const PATH_ADMIN_PLACE_LIST = "/admin/places"
const PATH_ADMIN_TAG_LIST = "/admin/tags"
const PATH_ADMIN_TAG_ADD = "/admin/tags/add"
const PATH_ADMIN_MAP = "/admin/map"
const PATH_ADMIN_TAG = "/tag/:tagId"

const PATH_MAP_CONTAINER = [PATH_MAP_VIEW, PATH_ADMIN_MAP]

const title = process.env.REACT_APP_META_TITLE
const description = process.env.REACT_APP_META_DESCRIPTION
const picture = process.env.REACT_APP_META_IMAGE

const HeadDefault = () => <Head {...{ title, description, picture }} />

const MainRouter = (props) => {
  const { isLoading, loadingMessage } = props

  const LEFT_ICONS_MAIN_VIEW = {
    left: {
      image: Assets.ICON_TAGS,
      to: "/admin/tags",
      keepQuery: true,
    },
    left2: {
      image: Assets.ICON_PLACE,
      to: "/admin/places",
      keepQuery: true,
    },
    left3: {
      image: Assets.ICON_USER,
      to: "/profile",
      keepQuery: true,
    },
  }

  const HEADER_PATHS = [
    {
      path: "/",
      exact: true,
    },
    {
      path: PATH_MAP_VIEW,
      exact: true,
      ...LEFT_ICONS_MAIN_VIEW,
      // left: { image: Assets.ICON_LIST, to: "/list", keepQuery: true },
    },
    {
      path: PATH_LIST_VIEW,
      exact: true,
      ...LEFT_ICONS_MAIN_VIEW,
      // left: { image: Assets.ICON_MAP, to: "/map", keepQuery: true },
    },
    {
      path: PATH_PLACE_VIEW,
      exact: true,
      left: { image: Assets.ICON_BACK, to: "/?source", keepQuery: true, hideOnDesktop: false },
    },
    {
      path: PATH_ADMIN_MAP,
      exact: true,
      left: { image: Assets.ICON_BACK, to: "/admin/places", keepQuery: true },
    },
    {
      path: PATH_ADMIN_TAG_ADD,
      exact: true,
      left: { image: Assets.ICON_BACK, to: "/admin/tags" },
    },
    {
      path: PATH_ADMIN_PLACE_LIST,
      exact: true,
      left: { image: Assets.ICON_BACK, to: "/?source" },
      left2: { image: Assets.ICON_ADD, to: "/admin/map", keepQuery: true },
    },
    {
      path: PATH_ADMIN_TAG_LIST,
      exact: true,
      left: { image: Assets.ICON_BACK, to: "/?source" },
      left2: { image: Assets.ICON_ADD, to: "/admin/tags/add", keepQuery: true },
    },
    {
      path: "*",
      exact: false,
      left: { image: Assets.ICON_BACK, to: "/?source", keepQuery: true },
    },
  ]

  return (
    <ScrollToTopOnRouteChange>
      <HeadDefault />
      <HeaderRouter routerPaths={HEADER_PATHS} {...props} />
      <Loading spinner show={isLoading} message={loadingMessage}>
        <Switch>
          <Route
            exact
            path={PATH_PROFILE}
            render={(x) => <LoginScreen {...props} {...x} isLoggedIn={true} />}
          />
          <Route exact path={"/"} render={(x) => <Redirect to={PATH_MAP_VIEW} />} />
          <Route exact path={PATH_MAP_VIEW} render={(x) => <MapScreen {...props} {...x} />} />
          <Route exact path={PATH_LIST_VIEW} render={(x) => <ListScreen {...props} {...x} />} />
          <Route exact path={PATH_PLACE_VIEW} render={(x) => <PlaceScreen {...props} {...x} />} />
          <Route
            exact
            path={PATH_ADMIN_PLACE_LIST}
            render={(x) => <AdminPlaceListScreen {...props} {...x} />}
          />

          <Route
            exact
            path={PATH_ADMIN_TAG_LIST}
            render={(x) => <AdminTagListScreen {...props} {...x} />}
          />

          <Route exact path={PATH_ADMIN_TAG} render={(x) => <AdminTagScreen {...props} {...x} />} />

          <Route
            exact
            path={PATH_ADMIN_TAG_ADD}
            render={(x) => <AdminTagAddScreen {...props} {...x} />}
          />

          <Route
            exact
            path={PATH_ADMIN_MAP}
            render={(x) => <MapScreen {...props} {...x} pathOnClickBack={"/admin/map"} />}
          />
        </Switch>
      </Loading>
    </ScrollToTopOnRouteChange>
  )
}

//////////////////////////////////
// FINAL RENDER

const RouterScreenRender = ({isLoggedIn, ...props}) => {
  return (
    <Switch>
      <Route exact path={"/privacy"} render={(x) => <PrivacyPolicyScreen />} />)
      <Route
        exact
        path={PATH_LOGIN}
        render={(x) => <LoginScreen {...props} {...x} isLoggedIn={false} />}
      />
      {isLoggedIn && (
        <Route
          path="*"
          render={(x) => {
            const isMap = x.location && PATH_MAP_CONTAINER.indexOf(x.location.pathname) !== -1
            return (
              <SafeAreaView style={isMap ? styles.containerFlex : styles.containerUndefined}>
                <MainRouter {...props} />
              </SafeAreaView>
            )
          }}
        />
      )}
    </Switch>
  )
}

export default isMobile(RouterScreenRender)
