import React from "react"
import StylesReader from "../../../hoc/StylesReader"

const Icon = ({ onPress, source, size, style, ...props }) => {
  const imageSize = size || 25
  const styleImage = {
    height: imageSize,
    width: imageSize,
  }
  return source ? (
    <button
      className={"clickable"}
      style={{ ...styles.button, ...style }}
      onClick={onPress}
      {...props}
      type="submit"
    >
      <img style={styleImage} src={source} alt="icon" />
    </button>
  ) : (
    <div style={style}>
      <div style={styleImage} />
    </div>
  )
}

const styles = {
  button: {
    display: "flex",
    background: "none",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
}

export default StylesReader(Icon)
