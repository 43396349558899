const Colors = {
  primary: process.env.REACT_APP_COLOR_PRIMARY || "#8dddf1",
  primaryDark: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#41a7c0",
  accent: process.env.REACT_APP_COLOR_ACCENT || "#f299b8",
  accentLight: process.env.REACT_APP_COLOR_ACCENT_LIGHT || "#fdedf0",
  neutral: process.env.REACT_APP_COLOR_NEUTRAL || "#888",
  error: process.env.REACT_APP_COLOR_ERROR || "#f66",
  textColor: process.env.REACT_APP_COLOR_TEXT || "#000",
  section: process.env.REACT_APP_COLOR_SECTION || "#F7F7F7",
  divider: process.env.REACT_APP_COLOR_DIVIDER || "#E9E9E9",
  disabledBackground: process.env.REACT_APP_COLOR_DISABLED_BACKGROUND || "#DDD",
  disabledForeground: process.env.REACT_APP_COLOR_DISABLED_FOREGROUND || "#888",
  black: process.env.REACT_APP_COLOR_BLACK || "#000",
  blackSoft: process.env.REACT_APP_COLOR_SOFT || "#777",
  mapAccent:
    process.env.REACT_APP_COLOR_MAP_ACCENT || process.env.REACT_APP_COLOR_ACCENT || "#f299b8",
  mapAccentBright: process.env.REACT_APP_COLOR_MAP_ACCENT_BRIGHT || "#ffa0ca",
  mapRoadGeometry: process.env.REACT_APP_COLOR_MAP_ROAD_GEOMETRY || "#f1ede5",
  mapWaterStroke: process.env.REACT_APP_COLOR_MAP_WATER_STROKE || "#f5f8fc",
  mapWaterFill: process.env.REACT_APP_COLOR_MAP_WATER_FILL || "#77eaff",
}

export default Colors
