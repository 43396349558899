import React, { Component } from "react"

import ArticleListAdminRender from "./render"
import { articleHasDescription, articleIsDone } from "../ArticleFinder/utils"

class ArticleListAdmin extends Component {
  state = {}

  // onSelectedArticle = place => {
  //   // this.props.goToArticleId(place.id)
  // }

  getArticlesListFromSelectedAdmin = () => {
    const { articlesList } = this.props
    const { valueAdmin } = this.state
    if (valueAdmin === "unassigned_articles")
      return articlesList.filter((x) => x.editorId == null || x.editorId === undefined)
    return valueAdmin ? articlesList.filter((x) => x.editorId === valueAdmin) : articlesList
  }

  sortArticlesList = (articlesList) => {
    if (!articlesList) return []
    const sortedArticlesList = articlesList.slice(0).sort(compareArticlesName)
    const articleDone = sortedArticlesList.filter(articleIsDone)
    const articleWip = sortedArticlesList.filter((x) => {
      const { cover, visibility } = x
      return articleDone.indexOf(x) === -1 && (articleHasDescription(x) || cover || visibility)
    })
    const articleLeft = sortedArticlesList.filter((x) => {
      return articleDone.indexOf(x) === -1 && articleWip.indexOf(x) === -1
    })
    return articleDone.concat(articleWip).concat(articleLeft)
  }

  handleChangeAdmin = ({ value: valueAdmin }) => this.setState({ valueAdmin })

  render() {
    const articlesList = this.sortArticlesList(this.getArticlesListFromSelectedAdmin())
    const total = articlesList ? articlesList.length : 0
    const visible = total > 0 ? articlesList.filter((x) => x.visibility === true).length : 0
    const done = total > 0 ? articlesList.filter(articleIsDone).length : 0
    const count = { total, visible, done }
    return (
      <ArticleListAdminRender
        {...this.props}
        {...this.state}
        {...{ count }}
        articlesList={articlesList}
        // onSelectedArticle={this.onSelectedArticle}
        handleChangeAdmin={this.handleChangeAdmin}
      />
    )
  }
}

const compareArticlesName = (a, b) => {
  const x = (a.name || "").toLowerCase()
  const y = (b.name || "").toLowerCase()
  if (x < y) return -1
  if (x > y) return 1
  return 0
}

export default ArticleListAdmin
