export const getDistanceBetweenLatLngInMeters = (lat1, lon1, lat2, lon2) => {
  var R = 6371000 // Radius of the earth in meters
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

export const getDistanceBetweenPositionsInMeters = (pos1, pos2) => {
  return getDistanceBetweenLatLngInMeters(pos1.lat, pos1.lng, pos2.lat, pos2.lng)
}
