import React from "react"

import Place from "../../components/Place"
import isMobile from "../../components/primitives/isMobile"
import Layout from "../../components/primitives/Layout"
import Head from "../../components/primitives/Head"

const PlaceScreenRender = ({ isMobile, place, handleClickBackPlaces, ...props }) => (
  <Layout style={styles.app} scrollable>
    {place ? (
      <Head
        title={`${place.name} - Zece La Rece`}
        description={place.description}
        picture={place.cover && (place.cover.big || place.cover.original)}
      />
    ) : null}
    <Place place={place} onPressBack={handleClickBackPlaces} {...props} />
  </Layout>
)

const styles = {
  app: {
    textAlign: "left",
    flex: 1,
    width: "100%",
  },
}

export default isMobile(PlaceScreenRender)
