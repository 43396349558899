import React, {Component} from "react"
import PropTypes from "prop-types"

import {database, storage} from "../../firebaseApp"

import ImageUploaderRender from "./render"

class ImageUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {isLoading: false}
    if (props.state) {
      this.state = {
        ...this.state,
        ...props.state,
      }
    }
  }

  UNSAFE_componentWillMount() {
    const {refUrl} = this.props
    this.setRefs(refUrl)
  }

  setRefs(refUrl) {
    if (this.refUrl !== refUrl) {
      this.refUrl = refUrl
      this.setState({imageUrlTemp: null})
      if (this.imageRef) this.imageRef.off()
      if (refUrl) {
        this.imageRef = database.ref(refUrl)
        this.imageStorageRefRef = this.imageRef.child("ref")
        this.imageUrlRef = this.imageRef.child("url")
        this.imageUrlRef.on("value", (snap) => {
          this.setState({imageUrl: snap.val()})
        })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {refUrl} = nextProps
    this.setRefs(refUrl)
  }

  onSelectFile = (file) => {
    const {fileRefUrl} = this.props
    if (fileRefUrl) {
      this.setState({isLoading: true})
      storage
        .ref(fileRefUrl)
        .put(file)
        .then(snap => snap.ref.getDownloadURL())
        .then(async (imageUrl) => {
          const imageUrlTemp = URL.createObjectURL(file)
          await this.imageUrlRef.set(imageUrl)
          await this.imageStorageRefRef.set(fileRefUrl)
          this.setState({imageUrlTemp, isLoading: false}, () => {
            if (this.props.onUploadedNewImage) {
              this.props.onUploadedNewImage(imageUrl)
            }
          })
        })
    }
  }

  onPressDeleteFile = () => {
    const {fileRefUrl} = this.props
    if (fileRefUrl) {
      this.setState({isLoading: true})
      storage
        .ref(fileRefUrl)
        .delete()
        .then(async () => {
          await this.imageUrlRef.remove()
          await this.imageStorageRefRef.remove()
          this.setState({imageUrlTemp: null, isLoading: false})
        })
    }
  }

  render() {
    return (
      <ImageUploaderRender
        {...this.props}
        {...this.state}
        onSelectFile={this.onSelectFile}
        onPressDeleteFile={this.onPressDeleteFile}
      />
    )
  }
}

ImageUploader.propTypes = {
  refUrl: PropTypes.string,
  fileRefUrl: PropTypes.string,
}

export default ImageUploader
