import React, { Component } from "react"
import Text from "../../components/primitives/Text"
import Layout from "../../components/primitives/Layout"
import { PRIVACY_POLICY } from "../../const/Strings"

class PrivacyPolicyScreen extends Component {
  render() {
    return (
      <Layout padding className="content-padded">
        <Text padding>{PRIVACY_POLICY}</Text>
      </Layout>
    )
  }
}

export default PrivacyPolicyScreen
