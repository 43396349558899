import React, { Component } from "react"

import { database } from "../../firebaseApp"

import Layout from "../primitives/Layout"
import Select from "../primitives/Select"

class AdminSelector extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    const adminsRefUrl = `admins/zecelarece`
    this.adminsRef = database.ref(adminsRefUrl)
    this.adminsRef.orderByChild("name").on("value", (snap) => {
      const optionsEditors = []
      snap.forEach((snap) => {
        const admin = snap.val()
        if (admin && (admin.status === "editor" || admin.status === "owner")) {
          const value = snap.key
          const label = admin.name || "Undefined"
          optionsEditors.push({ value, label })
        }
      })
      this.setState({
        optionsEditors: optionsEditors.concat(this.props.additionalOptions || []),
      })
    })
  }

  componentWillUnmount() {
    // if (this.adminsRef) this.adminsRef.off()
    // create bug when navigating, componentWillMount is not called anymore
  }

  onChange = (value) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value || { label: null, value: null })
    }
  }

  render() {
    const { style, value } = this.props
    const { optionsEditors } = this.state
    return (
      <Layout {...this.props}>
        <Select
          style={this.props.style}
          name="form-field-admins"
          {...{ style, value }}
          onChange={this.onChange}
          options={optionsEditors}
          clearable={true}
        />
      </Layout>
    )
  }
}

export default AdminSelector
