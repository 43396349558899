import React from "react"
import Select from "react-select"

import Layout from "../Layout"

const MultiSelectorRender = ({
  value,
  options,
  onChange,
  name,
  clearable,
  closeOnSelect,
}) => (
  <Layout>
    <Select
      multi
      {...{
        name,
        value,
        onChange,
        options,
        clearable,
        closeOnSelect,
      }}
    />
  </Layout>
)

MultiSelectorRender.defaultProps = {
  name: "form-field",
  closeOnSelect: false,
  clearable: false,
}

export default MultiSelectorRender
