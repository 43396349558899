import React from "react"

import { buildNavigationLinkWithSource } from "../../utils/PathUtils"

import AdminSelector from "../AdminSelector"
import TagMultiSelector from "../TagMultiSelector"
import BusinessOwnerMultiSelector from "../BusinessOwnerMultiSelector"
import Section from "../Section"
import SectionTitle from "../SectionTitle"
import Card from "../primitives/Card"
import Text from "../primitives/Text"
import Switch from "../primitives/Switch"
import ArticleAdminButtons from "../ArticleAdminButtons"
import ScheduledPostList from "../ScheduledPostList"
import Button from "../primitives/Button"
import Layout from "../primitives/Layout"
import Colors from "../../const/Colors"
import IsMobile from "../primitives/isMobile"

const PlaceAdminRender = ({
  isMobile,
  place,
  tags,
  valueEditors,
  optionsEditors,
  handleChangeEditor,
  handleChangeWebsite,
  viewCount,
  learnMoreCount,
  goThereCount,
  scheduledPosts,
  isScheduleButtonLoading,
  handleSchedulePost,
  handleCancelScheduledPost,
  handleDeletePlace,
  placeVisibility,
  hasUsedAiGenerationOnce,
  gptTemperature,
  gptFrequencyPenalty,
  gptPresencePenalty,
  gptWordCountLimit,
  gptIncludeGoogleReviews,
  gptAdditionalInfo,
  gptGeneratedDescription,
  googleReviews,
  isLoadingGptGeneratedDescription,
  handleChangePlaceVisibility,
  handleChangeGptTemperature,
  handleChangeGptFrequencyPenalty,
  handleChangeGptPresencePenalty,
  handleChangeGptWordCountLimit,
  handleChangeGptIncludeGoogleReviews,
  handleChangeGptAdditionalInfo,
  handleChangeGptGeneratedDescription,
  handleGptGenerateArticle,
  handleGptSaveAndReplaceDescription,
  ...props
}) => {
  const { id: placeId, tags: placeTags, owners: placeOwners, website } = place || {}
  const scheduledPostsTimes =
    scheduledPosts && Object.keys(scheduledPosts).map((key) => scheduledPosts[key])
  const hasScheduledPost = scheduledPostsTimes && scheduledPostsTimes.length
  const scheduledPostsTime = hasScheduledPost && scheduledPostsTimes[0] * 1000
  return (
    <Card {...props}>
      <Section bottomDivider title="Edit place">
        <TagMultiSelector {...{ placeId, placeTags, tags }} />
        <SectionTitle>{`Assigned to`}</SectionTitle>
        <AdminSelector
          style={{ flex: 1 }}
          marginHalf
          value={valueEditors}
          onChange={handleChangeEditor}
        />
        <BusinessOwnerMultiSelector {...{ placeId, placeOwners }} />
        <SectionTitle>{`Learn more url`}</SectionTitle>
        <Text
          small
          editable
          marginHalf
          singleLine
          editKey={placeId}
          onTextEdited={handleChangeWebsite}
          placeholder="Add here a facebook page, website, landing page..."
        >
          {website}
        </Text>
        <SectionTitle>{`Place visibility`}</SectionTitle>
        <Switch
          paddingHalf
          value={placeVisibility}
          onValueChange={handleChangePlaceVisibility}
          labelEnabled="This place is public and visible to everyone"
          labelDisabled="This place is not visible"
        />
      </Section>

      <Section bottomDivider title="Generate Description (ChatGPT)">
        <Layout horizontal={!isMobile}>
          <Layout>
            <SectionTitle>{`Article length`}</SectionTitle>
            <Text marginHalf xsmall>{`Word Count (roughly)\n-`}</Text>
            <Text
              small
              editable
              marginHalf
              singleLine
              editKey={placeId}
              mustValidateEdit={false}
              onTextEdited={handleChangeGptWordCountLimit}
              placeholder="Article word count (roughly)"
            >
              {gptWordCountLimit}
            </Text>
          </Layout>
          <Layout>
            <SectionTitle>{`Article creativity`}</SectionTitle>
            <Text marginHalf xsmall>{`Temperature\n(Default: 1., Min: 0. Max: 2.)`}</Text>
            <Text
              small
              editable
              marginHalf
              singleLine
              editKey={placeId}
              mustValidateEdit={false}
              onTextEdited={handleChangeGptTemperature}
              placeholder="Article creativity"
            >
              {gptTemperature}
            </Text>
          </Layout>
          <Layout>
            <SectionTitle>{`Avoid repetition`}</SectionTitle>
            <Text marginHalf xsmall>{`Frequency Penalty\n(Default: 0., Min: -2. Max: 2.)`}</Text>
            <Text
              small
              editable
              marginHalf
              singleLine
              editKey={placeId}
              mustValidateEdit={false}
              onTextEdited={handleChangeGptFrequencyPenalty}
              placeholder="Avoid repetition"
            >
              {gptFrequencyPenalty}
            </Text>
          </Layout>
          <Layout>
            <SectionTitle>{`Topic Diversity`}</SectionTitle>
            <Text marginHalf xsmall>{`Presence Penalty\n(Default: 0., Min: -2. Max: 2.)`}</Text>
            <Text
              small
              editable
              marginHalf
              singleLine
              editKey={placeId}
              mustValidateEdit={false}
              onTextEdited={handleChangeGptPresencePenalty}
              placeholder="Topic Diversity"
            >
              {gptPresencePenalty}
            </Text>
          </Layout>
        </Layout>
        <SectionTitle>{`Include google reviews (5 most relevant)`}</SectionTitle>
        <Switch
          paddingHalf
          value={gptIncludeGoogleReviews}
          onValueChange={handleChangeGptIncludeGoogleReviews}
          labelEnabled="Using google reviews to generate a description"
          labelDisabled="Using only custom instructions"
        />
        <SectionTitle>{`Additionnal information (fine tune)`}</SectionTitle>
        <Text
          small
          editable
          mustValidateEdit={false}
          marginHalf
          editKey={placeId}
          onTextEdited={handleChangeGptAdditionalInfo}
          placeholder="Add some key details about this place to personalize the article"
        >
          {gptAdditionalInfo}
        </Text>
        {googleReviews && googleReviews.length && (
          <>
            <SectionTitle>{`Included reviews`}</SectionTitle>
            {googleReviews.map((review) => (
              <Card marginHalf padding backgroundColor={Colors.accentLight}>
                <Text xsmall soft>
                  {`- "${review}"`}
                </Text>
              </Card>
            ))}
          </>
        )}
        {gptGeneratedDescription && (
          <>
            <SectionTitle>{`Generated description`}</SectionTitle>
            <Text
              small
              editable
              marginHalf
              editKey={placeId}
              mustValidateEdit={false}
              onTextEdited={handleChangeGptGeneratedDescription}
              placeholder=""
            >
              {gptGeneratedDescription}
            </Text>
          </>
        )}
        <Layout horizontal>
          <Button
            fullWidth
            accent
            topSpace
            onPress={handleGptGenerateArticle}
            enabled={!isLoadingGptGeneratedDescription}
          >
            {isLoadingGptGeneratedDescription
              ? `Generating article...`
              : `Generate article with ChatGPT`}
          </Button>
          <Button
            primary
            fullWidth
            topSpace
            onPress={handleGptSaveAndReplaceDescription}
            enabled={!!gptGeneratedDescription}
          >
            {`Save and replace description`}
          </Button>
        </Layout>
      </Section>

      {hasScheduledPost && (
        <Section bottomDivider title="Insights">
          <ScheduledPostList
            hasScheduledPost={hasScheduledPost}
            scheduledPostsTime={scheduledPostsTime}
          />
        </Section>
      )}

      <ArticleAdminButtons
        articleType="place"
        isScheduleButtonLoading={isScheduleButtonLoading}
        hasScheduledPost={hasScheduledPost}
        handleSchedulePost={handleSchedulePost}
        handleCancelScheduledPost={handleCancelScheduledPost}
        handleDeleteArticle={handleDeletePlace}
        toListUrl={buildNavigationLinkWithSource(props, `/admin/places`)}
      />
    </Card>
  )
}

PlaceAdminRender.defaultProps = {}

export default IsMobile(PlaceAdminRender)
