import { injectPlatformSpecificStylesInProps } from "./platform"
import { PADDING_HALF, PADDING } from "../../const/Dimensions"

export const injectStylesInProps = ({
  horizontal,
  vertical,
  center,
  centerVertical,
  alignBottom,
  alignTop,
  padding,
  paddingHalf,
  margin,
  marginHalf,
  topSpace,
  bottomSpace,
  leftSpace,
  rightSpace,
  topSpaceHalf,
  bottomSpaceHalf,
  leftSpaceHalf,
  rightSpaceHalf,
  style: parentStyles,
  spaceAround,
  spaceBetween,
  overflowHidden,
  soft,
  softer,
  softest,
  stretch,
  ...props
}) => {
  //const styles = style ? {...style} : { }
  let s = {
    flexDirection: horizontal ? "row" : "column",
  }

  if (centerVertical) s.alignItems = "center"
  else if (alignBottom) s.alignItems = "flex-end"
  else if (alignTop) s.alignItems = "flex-start"
  else if (stretch) s.alignItems = "stretch"

  if (center) {
    s.justifyContent = "center"
    s.textAlign = "center"
  } else {
    s.textAlign = "left"
    if (spaceAround) s.justifyContent = "space-around"
    else if (spaceBetween) s.justifyContent = "space-between"
  }

  if (padding) s = setPadding(s, PADDING)
  else if (paddingHalf) s = setPadding(s, PADDING_HALF)
  if (margin) s = setMargin(s, PADDING)
  else if (marginHalf) s = setMargin(s, PADDING_HALF)
  if (bottomSpace) s.marginBottom = PADDING
  if (topSpace) s.marginTop = PADDING
  if (rightSpace) s.marginRight = PADDING
  if (leftSpace) s.marginLeft = PADDING
  if (bottomSpaceHalf) s.marginBottom = PADDING_HALF
  if (topSpaceHalf) s.marginTop = PADDING_HALF
  if (rightSpaceHalf) s.marginRight = PADDING_HALF
  if (leftSpaceHalf) s.marginLeft = PADDING_HALF
  if (overflowHidden) s.overflow = "hidden"
  if (soft) s.opacity = 0.5
  else if (softer) s.opacity = 0.22
  else if (softest) s.opacity = 0.1

  const style = Array.isArray(parentStyles) ? [s].concat(parentStyles) : [s, parentStyles]

  return injectPlatformSpecificStylesInProps({ ...props, style })
}

const setPadding = (style, padding) => {
  return {
    ...style,
    ...{
      paddingTop: padding,
      paddingBottom: padding,
      paddingLeft: padding,
      paddingRight: padding,
    },
  }
}

const setMargin = (style, margin) => {
  return {
    ...style,
    ...{
      marginTop: margin,
      marginBottom: margin,
      marginLeft: margin,
      marginRight: margin,
    },
  }
}
