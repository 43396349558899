import React from "react"
import TabBar from "../../primitives/TabBar"
import Text from "../../primitives/Text"

const TagFilter = (props) => {
  return (
    <TabBar
      {...props}
      renderTitle={(title, i) => (
        <Text key={title} id={title} center uppercase small bold singleLine>
          {title}
        </Text>
      )}
    />
  )
}

export default TagFilter
