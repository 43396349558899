import React, { Component } from "react"
import PropTypes from "prop-types"

import { database, storage } from "../../firebaseApp"

import ImageCoverUploaderRender from "./render"

class ImageCoverUploader extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: false }
    if (props.state) {
      this.state = {
        ...this.state,
        ...props.state,
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { refUrl } = this.props
    this.setRefs(refUrl)
  }

  setRefs(refUrl) {
    if (this.refUrl !== refUrl) {
      this.refUrl = refUrl
      this.setState({ coverUrlTemp: null })
      if (this.imageRef) this.imageRef.off()
      if (refUrl) {
        const ref = database.ref(refUrl)
        this.imageRef = ref.child("cover")
        this.coverStorageRefRef = this.imageRef.child("originalRef")
        this.coverUrlRef = this.imageRef.child("original")
        this.coverAuthor = this.imageRef.child("author")
        // console.log('this.coverRef', this.coverRef)
        this.imageRef.on("value", (snap) => {
          this.setState({ cover: snap.val() })
        })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { refUrl } = nextProps
    this.setRefs(refUrl)
  }

  componentWillUnmount() {
    // this seems to create a bug
    // when opening another view with the same cover
    // the unmount is called after and the ref is not triggering the callback anymore
    // if (this.coverRef) this.coverRef.off()
  }

  onSelectFile = ({ file, author }) => {
    const { fileRefUrl } = this.props
    if (fileRefUrl) {
      this.setState({ isLoading: true })
      storage
        .ref(fileRefUrl)
        .put(file)
        .then(snap => snap.ref.getDownloadURL())
        .then((coverUrl) => {
          const coverUrlTemp = URL.createObjectURL(file)
          this.coverUrlRef.set(coverUrl)
          this.coverStorageRefRef.set(fileRefUrl)
          this.coverAuthor.set(author ? author : null)
          this.setState({ coverUrlTemp, isLoading: false }, () => {
            if (this.props.onUploadedNewCover) {
              this.props.onUploadedNewCover(coverUrl)
            }
          })
        })
    }
  }

  render() {
    return (
      <ImageCoverUploaderRender
        {...this.props}
        {...this.state}
        cover={this.state.cover || this.props.cover}
        onSelectFile={this.onSelectFile}
      />
    )
  }
}

ImageCoverUploader.propTypes = {
  refUrl: PropTypes.string,
  fileRefUrl: PropTypes.string,
}

export default ImageCoverUploader
