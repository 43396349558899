import React from "react"

import SmallValue from "../SmallValue"
import Text from "../primitives/Text"
import { getFormattedDistanceFromValue } from "./utils"

const Distance = ({ value, bordered, ...props }) => {
  const text = getFormattedDistanceFromValue(value)
  return bordered ? (
    <SmallValue {...props}>{text}</SmallValue>
  ) : (
    <Text small soft {...props}>
      {text}
    </Text>
  )
}

Distance.defaultProps = {
  bordered: true,
}

export default Distance
