import React from "react"

const Layout = ({
  isMobile,
  children,
  scrollable,
  onPress,
  htmlTag,
  imageSize,
  ...props
}) => {
  const LayoutTag = htmlTag || `div`

  return (
    <LayoutTag onClick={onPress} {...props}>
      {children}
    </LayoutTag>
  )
}

export default Layout
