import React from "react"
import { withRouter } from "../../../compat/Router"

class ScrollToTopOnRouteChange extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (typeof window !== "undefined" && window && window.scrollTo) window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTopOnRouteChange)
