export const buildClassNameText = ({
  size,
  xsmall,
  small,
  medium,
  mediumBig,
  big,
  huge,
  classNameText,
  ...props
}) => {
  let className = classNameText + " primitive-text"
  if (size) className += ` primitive-text-${size}`
  if (xsmall) className += " primitive-text-xsmall"
  if (small) className += " primitive-text-small"
  if (medium) className += " primitive-text-medium"
  if (mediumBig) className += " primitive-text-mediumBig"
  if (big) className += " primitive-text-big"
  if (huge) className += " primitive-text-huge"

  className = className.trim()
  return { className, props }
}

export const buildStylesText = ({
  editable,
  uppercase,
  lowercase,
  capitalize,
  singleLine,
  ...props
}) => {
  // const { singleLine } = props // don't extract those
  const styles = {}
  styles.whiteSpace = singleLine ? "nowrap" : "pre-line"
  if (uppercase) styles.textTransform = "uppercase"
  else if (lowercase) styles.textTransform = "lowercase"
  else if (capitalize) styles.textTransform = "capitalize"
  return { styles, props }
}
