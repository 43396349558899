import React, { Component } from "react"
import { database } from "../../firebaseApp"
import TagMultiSelectorRender from "./render"

class TagMultiSelector extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.updateValue(this.props)
    this.setRefs(this.props.placeId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { placeId: prevPlaceId, placeTags: prevPlaceTags } = this.props
    const { placeId, placeTags } = nextProps
    const prevPlaceTagsLength = (prevPlaceTags && prevPlaceTags.length) || 0
    const placeTagsLength = (placeTags && placeTags.length) || 0
    if (prevPlaceId !== placeId || prevPlaceTagsLength !== placeTagsLength) {
      this.updateValue(nextProps)
      if (prevPlaceId !== placeId) this.setRefs(placeId)
    }
  }

  setRefs = (placeId) => {
    const placeRef = database.ref(`places/zecelarece/${placeId}`)
    this.tagsRef = placeRef.child("tags")
  }

  updateValue = (props) => {
    const { placeTags } = props

    const valueTag = []

    if (placeTags) {
      for (let key in placeTags) {
        if (placeTags.hasOwnProperty(key)) {
          const tag = placeTags[key]
          if (tag) {
            const { name } = tag
            valueTag.push({ value: key, label: name })
          }
        }
      }
    }
    this.setState({ valueTag })
  }

  onChangeTag = (value) => {
    this.setState({ valueTag: value })
  }

  onAddTagToSelection = (option) => {
    this.setRefFromOption(this.tagsRef, option)
  }

  onRemoveTagFromSelection = (option) => {
    this.removeRefValueFromOption(this.tagsRef, option)
  }

  setRefFromOption = (ref, option) => {
    const { value: id, label: name } = option
    if (ref) ref.child(id).set({ id, name })
  }

  removeRefValueFromOption = (ref, option) => {
    if (ref) ref.child(option.value).remove()
  }

  render() {
    const { tags } = this.props
    const { valueTag } = this.state
    const optionsTag = []
    for (let key in tags) {
      if (tags.hasOwnProperty(key)) {
        const { name } = tags[key]
        optionsTag.push({ value: key, label: name })
      }
    }
    // options.sort((a, b) => a.label.localeCompare(b.label))
    return (
      <TagMultiSelectorRender
        // {...this.state}
        {...this.props}
        tagProps={{
          options: optionsTag,
          value: valueTag,
          onChange: this.onChangeTag,
          onAddToSelection: this.onAddTagToSelection,
          onRemoveFromSelection: this.onRemoveTagFromSelection,
        }}
      />
    )
  }
}

TagMultiSelector.defaultProps = {}

export default TagMultiSelector
