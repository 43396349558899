import React, { Component } from "react"
import { database } from "../../firebaseApp"

import Layout from "../../components/primitives/Layout"
import Text from "../../components/primitives/Text"
import Card from "../../components/primitives/Card"
import { makeIdFromName } from "../../utils/PathUtils"

class AdminTagAddScreen extends Component {
  state = {}

  onNameEdited = (nameDirty) => {
    const name = nameDirty && nameDirty.trim()
    const { tagsList } = this.props
    const tagId = makeIdFromName(name)
    if (
      tagsList.filter((tag) => tag.name && (makeIdFromName(tag.name) === tagId || tag.id === tagId))
        .length > 0
    ) {
      this.setState({ name: "", error: "This tag already exists" })
    } else {
      database.ref(`tags/zecelarece/${tagId}`).set({ id: tagId, name, order: 9999 }, () => {
        window.location.replace(`/tag/${tagId}?source=admin%2Ftags`)
      })
    }
  }

  render() {
    const { location } = this.props
    const { name, error } = this.state
    return (
      <Layout scrollable className="content-padded">
        <Card paddingHalf>
          <Text black soft medium bold paddingHalf>
            {`Add a tag`}
          </Text>
          <Layout topSpace>
            <Layout topSpace bottomSpace>
              <Text
                big
                bold
                singleLine
                marginHalf
                editKey={location.url + location.search}
                editable={true}
                placeholder={"Create your tag name"}
                onTextEdited={this.onNameEdited}
              >
                {name}
              </Text>
              {error && <Text error>{error}</Text>}
            </Layout>
          </Layout>
        </Card>
      </Layout>
    )
  }
}

export default AdminTagAddScreen
