import React, { Component } from "react"
import PlaceScreenRender from "./render"

class PlaceScreen extends Component {
  // state = { place:null }

  UNSAFE_componentWillMount() {
    this.onPlaceChanged(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onPlaceChanged(nextProps)
  }

  onPlaceChanged = (props) => {
    const {
      places,
      markers,
      match: {
        params: { placeId },
      },
    } = props
    if (this.placeId !== placeId) {
      this.placeId = placeId
      const place = places[placeId]
      this.marker = null
      this.markers = markers ? markers.slice() : [] // make copy of array
      if (place) {
        const { id: key, name, position, visibility: isPublic } = place
        this.marker = { name, position, key, id: key, isPublic }
        if (this.markers && this.markers.map((x) => x.key).indexOf(key) === -1)
          this.markers.push(this.marker) // add marker on the map even if suppoed to be hidden
      }
    }
  }

  render() {
    return (
      <PlaceScreenRender
        {...this.props}
        {...this.state}
        marker={this.marker}
        markers={this.markers}
      />
    )
  }
}

export default PlaceScreen
