import React, { Component } from "react"
import { open } from "../../utils/UrlOpener"
import { database } from "../../firebaseApp"
import ArticleDetailRender from "./render"

class ArticleDetail extends Component {
  state = {
    isEditingPosition: false,
    initialPosition: null,
  }

  UNSAFE_componentWillMount() {
    this.onComponentUpdate(this.props, this.state)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.onComponentUpdate(nextProps, nextState)
  }

  onComponentUpdate(props, state) {
    const { articleRefUrl } = props
    this.setRefs(articleRefUrl)
    const { marker } = props
    const { marker: markerState } = state
    if ((marker || {}).id !== (markerState || {}).id) {
      this.setState({ marker })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe() {
    if (this.editorIdRef) this.editorIdRef.off()
    if (this.editorRef) this.editorRef.off()
  }

  setRefs(articleRefUrl) {
    if (!articleRefUrl || articleRefUrl !== this.articleRefUrl) {
      this.articleRefUrl = articleRefUrl
      this.setState({ name: " ", description: "", editor: null })
      this.unsubscribe()
      if (articleRefUrl) {
        this.articleRef = database.ref(articleRefUrl)
        this.positionRef = this.articleRef.child(`position`)
        this.editorIdRef = this.articleRef.child(`editorId`)
        this.editorIdRef.on("value", (snap) => {
          const editorId = snap.val()
          this.editorRef = database.ref(`admins/zecelarece/${editorId}`)
          this.editorRef.on("value", (snap) => {
            this.setState({ editor: snap.val() })
          })
        })
        this.positionRef.once("value").then((snap) => {
          const initialPosition = snap.val()
          this.setState({ initialPosition })
        })
      }
    }
  }

  onClickDirection = (direction) => {
    const { marker } = this.state
    if (marker) {
      const offset = 0.0001
      if (direction === "up" || direction === "down") {
        marker.position.lat += direction === "up" ? offset : -offset
      } else {
        marker.position.lng += direction === "right" ? offset : -offset
      }
      marker.key = `${marker.position.lat} ${marker.position.lng} ${Date.now()}`
    }
    this.setState({ marker, isEditingPosition: true })
  }

  onClickCancel = () => {
    const { marker } = this.props
    const { initialPosition } = this.state
    if (marker) {
      marker.position.lat = Number(initialPosition.lat)
      marker.position.lng = Number(initialPosition.lng)
      marker.key = `${marker.position.lat} ${marker.position.lng} ${Date.now()}`
      this.setState({ marker, isEditingPosition: false })
    }
  }

  onClickValidate = () => {
    const { marker } = this.state
    const { position } = marker || {}
    if (position) {
      this.positionRef.set(position).then(() => {
        this.setState({
          isEditingPosition: false,
          initialPosition: {
            lat: Number(position.lat),
            lng: Number(position.lng),
          },
        })
      })
    }
  }

  onDescriptionEdited = (text) => {
    // todo: should we do some string validation before sending the change ?
    this.articleRef.child("description").set(text) //.then(() => { })
  }

  onNameEdited = (text) => {
    this.articleRef.child("name").set(text) //.then(() => { })
  }

  onPressLearnMore = (e) => {
    const {
      article: { website },
    } = this.props
    open(website, true)
  }

  onPressGoThere = (e) => {
    const {
      article: { name, placeName, address },
    } = this.props
    const destination = encodeURI((placeName || name) + " " + address)
    open("https://www.google.com/maps/dir/?api=1&destination=" + destination, true)
  }

  onPressGoogleUpdate = async () => {
    const areYouSureText = `Are you sure you want to replace this places's current data with the latest one from google maps?\n\nFields that will be updated:\n- name\n- website (learn more link)\n- position\n- address`
    if (window.confirm(areYouSureText)) {
      try {
        const { id: placeId } = this.props.article
        const res = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/places/update/google?placeId=${placeId}`
        )
        const {
          data: { changes },
        } = await res.json()
        const { length } = changes || {}
        if (length) {
          alert(`Success! ${length} fields were updated: \n${JSON.stringify(changes, null, 2)}`)
          window.location.reload()
        } else {
          alert(
            `Everything went fine, no field were updated, the date were already fresh and juicy.`
          )
        }
      } catch (err) {
        alert("We had an issue updating the place: " + (err.message || JSON.stringify(err)))
      }
    }
  }

  render() {
    const { article, markers, ...props } = this.props
    const { initialPosition, marker } = this.state
    return (
      // order of props matters
      <ArticleDetailRender
        {...props}
        {...this.state}
        {...article}
        article={article}
        marker={marker}
        markers={[marker]}
        canEditPosition={!!initialPosition}
        onNameEdited={this.onNameEdited}
        onDescriptionEdited={this.onDescriptionEdited}
        onClickDirection={this.onClickDirection}
        onClickCancel={this.onClickCancel}
        onClickValidate={this.onClickValidate}
        // onSelectFile={this.onSelectFile}
        onPressLearnMore={this.onPressLearnMore}
        onPressGoThere={this.onPressGoThere}
        onPressGoogleUpdate={this.onPressGoogleUpdate}
      />
    )
  }
}

export default ArticleDetail
