import React from "react"
import PropTypes from "prop-types"

import isMobile from "../primitives/isMobile"
import EditableImage from "../primitives/EditableImage"

const ImageUploaderRender = ({
                               imageUrl,
                               imageUrlTemp,
                               fileRefUrl,
                               refUrl,
                               ...props
                             }) => {
  return <EditableImage editable imageUrl={imageUrlTemp ? imageUrlTemp : imageUrl} {...props} />
}

ImageUploaderRender.propTypes = {
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  image: PropTypes.object,
  imageUrlTemp: PropTypes.string,
  imageSize: PropTypes.string,
}

export default isMobile(ImageUploaderRender)
