import React from "react"
import isMobile from "../primitives/isMobile"

const Logo = ({ style, isMobile, onPress, source }) => {
  const styles = stylesSheet(isMobile)
  return (
    <div style={{ ...styles.logoContainer, ...style }}>
      <img style={styles.logo} onClick={onPress} src={source} alt="logo" />
    </div>
  )
}

const stylesSheet = (isMobile) => ({
  logoContainer: {
    height: isMobile ? 45 : 50,
    width: isMobile ? 110 : 150,
    padding: 10,
    margin: 0,
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
})

export default isMobile(Logo)
