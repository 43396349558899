import React, { Component } from "react"

import SearchMarkerRender from "./render"

class SearchMarker extends Component {
  state = {}

  UNSAFE_componentWillMount() {}

  render() {
    return <SearchMarkerRender {...this.props} {...this.state} />
  }
}

export default SearchMarker
