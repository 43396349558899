import React from "react"
import Text from "../Text"
import LoadingSpinner from "./LoadingSpinner"
import Layout from "../Layout"

const Loading = ({ message, show, children, spinner, ...props }) => {
  if (show)
    return (
      <Layout
        centerVertical
        style={{ display: "flex", flexDirection: "column" }}
        {...props}
        vertical
        paddingHalf
      >
        {message && (
          <Text classNameText="content-padded" paddingHalf center small bold>
            {message}
          </Text>
        )}
        {spinner && <LoadingSpinner />}
      </Layout>
    )
  return children ? children : null
}

export default Loading
