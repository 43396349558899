import moment from "moment"
import React, { Fragment } from "react"
import SectionTitle from "./SectionTitle"
import Text from "./primitives/Text"

const ScheduledPostList = ({ hasScheduledPost, scheduledPostsTime, ...props }) => {
  return hasScheduledPost ? (
    <Fragment>
      <SectionTitle>{`Facebook Scheduled post`}</SectionTitle>
      <Text small paddingHalf>{`${moment(scheduledPostsTime).format("LLLL")}`}</Text>
    </Fragment>
  ) : null
}
export default ScheduledPostList
