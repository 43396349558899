import Colors from "../../const/Colors"

export const DEFAULT_ZOOM = Number(process.env.REACT_APP_MAP_DEFAULT_ZOOM)
export const DEFAULT_CENTER = {
  // Bucharest
  lat: Number(process.env.REACT_APP_MAP_DEFAULT_LAT),
  lng: Number(process.env.REACT_APP_MAP_DEFAULT_LNG),
}
export const ICON_SIZE = {
  default: 40,
  selected: 52,
}

export const MAP_STYLES = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: -90,
      },
      {
        lightness: 90,
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#523735",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        color: Colors.mapAccentBright,
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: "on",
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
        color: Colors.mapAccentBright,
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: Colors.mapAccentBright,
      },
      {
        saturation: -100,
      },
      {
        lightness: 30,
      },
      {
        weight: 4.5,
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: -90,
      },
      {
        lightness: 90,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        lightness: 100,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#93817c",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        weight: 3,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapRoadGeometry,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: 100,
      },
      {
        lightness: 65,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: 100,
      },
      {
        lightness: 100,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        lightness: 65,
      },
      {
        weight: 2.5,
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        weight: 5,
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: -100,
      },
      {
        lightness: 80,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#737371",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: Colors.mapAccent,
      },
      {
        saturation: 100,
      },
      {
        lightness: 80,
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8f7d77",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      // {
      //   color: "#dfd2ae",
      // },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: Colors.mapWaterFill,
      },
      // {
      //   saturation: 100,
      // },
      // {
      //   lightness: 50,
      // },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: Colors.mapWaterStroke,
      },
      {
        visibility: "on",
      },
      {
        weight: 4.5,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#92998d",
      },
    ],
  },
]
