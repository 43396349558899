import React, { Component } from "react"

import ArticleListAdmin from "../../components/ArticleListAdmin"
import ArticleItem from "../../components/ArticleItem"
import TagAdmin from "../../components/TagAdmin"
import Layout from "../../components/primitives/Layout"
import Text from "../../components/primitives/Text"
import Card from "../../components/primitives/Card"

class AdminTagScreen extends Component {
  state = {}

  render() {
    const {
      isMobile,
      placesListFiltered: articlesList,
      selectedTag: tag,
      markers,
      places,
      location,
      match,
    } = this.props
    // const name = tag && tag.name
    return tag ? (
      <Layout scrollable className="content-padded">
        <ArticleItem
          editable
          refUrl={`tags/zecelarece/${tag.id}`}
          fileRefUrl={`image/zecelarece/tag_cover/${tag.id}.jpg`}
          imageFullScreen={isMobile}
          placeholderName="Add a tag name"
          placeholderDescription="Add a tag description"
          showVisibility={false}
        />
        <TagAdmin
          {...{
            selectedTag: tag,
            markers,
            places,
          }}
        />
        <Card paddingHalf margin={isMobile}>
          <Text black soft medium bold paddingHalf>{`Places with this tag`}</Text>
          <Layout topSpace>
            <ArticleListAdmin
              {...{ articlesList, location, match }}
              articleName="Place"
              articlePath="place"
            />
          </Layout>
        </Card>
      </Layout>
    ) : null
  }
}

export default AdminTagScreen
