import React from "react"
import ChildText from "./Text"
import Colors from "../../../const/Colors"
import StylesReader from "../../../hoc/StylesReader"

const Text = ({
  // size,
  bold,
  style: parentStyles,
  // small,
  // medium,
  // big,
  // huge,
  error,
  accent,
  neutral,
  primary,
  primaryDark,
  soft,
  white,
  onTextEdited,
  //editable,
  ...props
}) => {
  const styles = {}
  if (bold) styles.fontWeight = "bold"
  // if (soft) styles.opacity = 0.5
  if (white) styles.color = "white"
  if (accent) styles.color = Colors.accent
  else if (primary) styles.color = Colors.primary
  else if (primaryDark) styles.color = Colors.primaryDark
  else if (neutral) styles.color = Colors.neutral
  else if (error) styles.color = Colors.error
  else if (!styles.color) styles.color = Colors.textColor

  const stylesArray = Array.isArray(parentStyles)
    ? [styles].concat(parentStyles)
    : [styles, parentStyles]

  return <ChildText {...props} style={stylesArray} onTextEdited={onTextEdited} />
}

export default StylesReader(Text)
