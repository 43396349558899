import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ title, description, picture, ...props }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {picture && <meta property="og:image" content={picture} />}
    </Helmet>
  )
}

export default Head
