import React from "react"
import PlaceList from "../../components/PlaceList"
import isMobile from "../../components/primitives/isMobile"
import Layout from "../../components/primitives/Layout"
import Head from "../../components/primitives/Head"
import ArticleItem from "../../components/ArticleItem"
import TagAdmin from "../../components/TagAdmin"

const ListScreenRender = ({
  isMobile,
  places,
  markers,
  selectedTag,
  handleMarkerClick,
  tagRefUrl,
  tagFileRefUrl,
  strings,
}) => {
  const article = selectedTag
  const listKey = article ? article.id : "undefined"
  return (
    <Layout style={styles.app} scrollable>
      {article ? (
        <Head
          title={`${article.name} - Zece La Rece`}
          description={article.description}
          picture={article.cover && (article.cover.big || article.cover.original)}
        />
      ) : null}

      <PlaceList
        listKey={listKey}
        renderHeader={() => (
          <Layout topSpace={!selectedTag}>
            {selectedTag && (
              <ArticleItem
                refUrl={tagRefUrl}
                fileRefUrl={tagFileRefUrl}
                imageFullScreen
                imageSize={isMobile ? "medium" : "big"}
                placeholderName="Add a tag name"
                placeholderDescription="Add a tag description"
                showVisibility={false}
                showEditor={true}
                editable
                {...{
                  article,
                }}
                // state={{ ...article }}
              />
            )}
            {selectedTag ? (
              <TagAdmin
                className="content-padded"
                {...{
                  selectedTag,
                  markers,
                  places,
                }}
              />
            ) : null}
          </Layout>
        )}
        {...{
          isMobile,
          markers,
          places,
          handleMarkerClick,
          strings,
        }}
      />
    </Layout>
  )
}

const styles = {
  app: {
    textAlign: "left",
    flex: 1,
    width: "100%",
    // alignItems: 'stretch',
    // justifyContent: 'stretch',
    height: "100%",
  },
  facebookLike: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 16,
    paddingBottom: 5,
  },
}

export default isMobile(ListScreenRender)
