import React from "react"

import Layout from "../Layout"
import Text from "../Text"

const ListView = ({ isMobile, data, renderItem, renderHeader, renderFooter, emptyText }) =>
  data && (
    <Layout>
      {renderHeader && renderHeader()}
      {data && data.length > 0 ? (
        data.map((item, index) => renderItem({ item, index }))
      ) : emptyText ? (
        <Text bold center padding accent>
          {emptyText}
        </Text>
      ) : null}
      {renderFooter && renderFooter()}
    </Layout>
  )

export default ListView
