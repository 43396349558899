import React from "react"
import PropTypes from "prop-types"

import Text from "../primitives/Text"
import Layout from "../primitives/Layout"
import Switch from "../primitives/Switch"
import ImageCoverUploader from "../ImageCoverUploader"
import SmallValue from "../SmallValue"
import Author from "../Author"

const ArticleItemRender = ({
  editable,
  isMobile,
  name,
  description,
  value,
  editor,
  visibility,
  hasUsedAiGenerationOnce,
  onChangeVisibility,
  onNameEdited,
  onDescriptionEdited,
  fileRefUrl,
  refUrl,
  style,
  placeholderName,
  placeholderDescription,
  showName,
  showEditor,
  showDescription,
  showVisibility,
  imageSize,
  articleType,
  ...props
}) => {
  const styleNoPaddingTop = isMobile && { paddingTop: 0 }
  const propsSpacing = {
    margin: isMobile,
    bottomSpace: !isMobile,
  }
  const propsSpacingNoPaddingTop = { ...propsSpacing, style: styleNoPaddingTop }
  const NameWithProps = showName ? (
    <Text
      big
      bold
      centerVertical
      editKey={refUrl}
      editable={editable}
      placeholder={placeholderName}
      rightSpace
      onTextEdited={onNameEdited}
    >
      {name}
    </Text>
  ) : null
  const ValueWithProps = value ? <SmallValue>{value}</SmallValue> : null
  const DescriptionWithProps = showDescription ? (
    <Text
      small
      {...propsSpacingNoPaddingTop}
      editKey={refUrl}
      editable={editable}
      placeholder={placeholderDescription}
      onTextEdited={onDescriptionEdited}
    >
      {description}
    </Text>
  ) : null
  // console.log('item render cover',cover)
  return (
    <Layout style={{ ...style, ...{ overflow: "hidden" } }} {...props}>
      <ImageCoverUploader
        imageSize={imageSize}
        editable={editable}
        refUrl={refUrl}
        fileRefUrl={fileRefUrl}
        // fullScreen={imageFullScreen}
        bottomSpace={!isMobile}
        fullScreen
        // cover={cover}
        // state={cover}
      />
      <Layout className="content-padded">
        <Layout horizontal wrap centerVertical {...propsSpacing}>
          {NameWithProps}
          {ValueWithProps}
        </Layout>
        {DescriptionWithProps}
        {hasUsedAiGenerationOnce && (<Text xsmall soft {...propsSpacing}>{`🤖 Article written using AI generation at least once`}</Text>)}
        {showEditor && editor && (
          <Author
            name={editor.name}
            facebookUrl={editor.facebook}
            instagramUrl={editor.instagram}
            // {...propsSpacingNoPaddingTop}
          />
        )}
        {editable && showVisibility && (
          <Switch
            {...propsSpacingNoPaddingTop}
            value={visibility}
            onValueChange={onChangeVisibility}
            labelEnabled={`This ${articleType} is public and visible to everyone`}
            labelDisabled={`This ${articleType} is not visible`}
          />
        )}
      </Layout>
    </Layout>
  )
}

ArticleItemRender.defaultProps = {
  showName: true,
  showDescription: true,
  showVisibility: true,
  showAuthor: false,
  articleType: "article",
}

ArticleItemRender.propTypes = {
  editable: PropTypes.bool.isRequired,
  showName: PropTypes.bool,
  refUrl: PropTypes.string,
  fileRefUrl: PropTypes.string,
}

export default ArticleItemRender
