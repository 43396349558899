import React from "react"

import Layout from "../primitives/Layout"
import Image from "../primitives/Image"
import Assets from "../../const/Assets"

const PositionEditor = ({
  style = {},
  isEditingPosition,
  onClickDirection,
  onClickCancel,
  onClickValidate,
}) => {
  return (
    <Layout horizontal style={{ ...s.main, ...style }}>
      {isEditingPosition && (
        <Layout spaceBetween marginRight style={s.viewValidation}>
          <Image source={Assets.IMG_CANCEL} style={s.button} marginHalf onClick={onClickCancel} />
          <Image source={Assets.IMG_VALIDATE} style={s.button} marginHalf onClick={onClickValidate} />
        </Layout>
      )}
      <Layout center centerVertical>
        <Image source={Assets.IMG_ARROW_UP} style={s.button} onClick={() => onClickDirection("up")} />
        <Layout horizontal style={s.sides}>
          <Image
            source={Assets.IMG_ARROW_LEFT}
            style={s.button}
            onClick={() => onClickDirection("left")}
          />
          <Layout style={s.icon} />
          <Image
            source={Assets.IMG_ARROW_RIGHT}
            style={s.button}
            onClick={() => onClickDirection("right")}
          />
        </Layout>
        <Image source={Assets.IMG_ARROW_DOWN} style={s.button} onClick={() => onClickDirection("down")} />
      </Layout>
    </Layout>
  )
}

const size = 25
const icon = {
  height: size,
  width: size,
}
const s = {
  main: {
    backgroundColor: "white",
    boxShadow: "-1px -4px 1px rgba(0, 0, 0, .045)",
    alignItems: "flex-end",
  },
  icon: icon,
  button: {
    ...icon,
    cursor: "pointer",
  },
  sides: {
    display: "flex",
    flexDirection: "row",
  },
  viewValidation: {
    height: "100%",
  },
}

export default PositionEditor
