import React, { Component } from "react"
import { database } from "../../firebaseApp"
import { articleHasDescription, articleHasCover, articleIsDone } from "./utils"
import { Link } from "../../compat/Router"

import Colors from "../../const/Colors"

import Text from "../primitives/Text"
import Card from "../primitives/Card"
import Layout from "../primitives/Layout"
import Icon from "../primitives/Icon"
import Assets from "../../const/Assets"

class ArticleFinderRow extends Component {
  state = { editorName: "" }

  UNSAFE_componentWillMount() {
    this.setRefs(this.props)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.setRefs(nextProps)
  }

  componentWillUnmount() {
    if (this.editorNameRef) this.editorNameRef.off()
  }

  setRefs = (props) => {
    const { article } = props
    if (article) {
      const { editorId } = article
      this.editorNameRef = database.ref(`admins/zecelarece/${editorId}/name`)
      this.editorNameRef.once("value", (snap) => {
        const editorName = snap.val()
        if (this.state.editorName !== editorName) this.setState({ editorName })
      })
    }
  }

  render() {
    return <ArticleFinderRowRender {...this.props} {...this.state} />
  }
}

const ArticleFinderRowRender = ({ article, editorName, to, ...props }) => {
  const { name, visibility } = article
  const hasDescription = articleHasDescription(article)
  const hasCover = articleHasCover(article)
  const isDone = articleIsDone(article)
  const size = 22
  return (
    <Layout className="clickable">
      <Link
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "stretch",
        }}
        to={to}
      >
        <Card marginHalf horizontal centerVertical spaceBetween soft={!visibility}>
          <Text margin neutral>
            {name}
          </Text>
          <Layout horizontal centerVertical>
            {editorName && (
              <Card backgroundColor={Colors.accentLight}>
                <Text accent marginHalf>
                  {editorName}
                </Text>
              </Card>
            )}
            {hasDescription && <Icon source={Assets.ICON_TEXT} size={size} paddingHalf />}
            {hasCover && <Icon source={Assets.ICON_IMAGE} size={size} paddingHalf />}
            {isDone && <Icon source={Assets.ICON_DONE} size={size} paddingHalf />}
          </Layout>
        </Card>
      </Link>
    </Layout>
  )
}

export default ArticleFinderRow
