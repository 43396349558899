import React from "react"
import Section from "./Section"
import Button from "./primitives/Button"
import ButtonLink from "./primitives/ButtonLink"

const ArticleAdminButtons = ({
  isScheduleButtonLoading,
  hasScheduledPost,
  handleSchedulePost,
  handleCancelScheduledPost,
  handleDeleteArticle,
  articleType,
  toListUrl,
  ...props
}) => (
  <Section padding>
    {/*<Button accent fullWidth topSpace*/}
    {/*  enabled={!isScheduleButtonLoading}*/}
    {/*  onPress={hasScheduledPost ? handleCancelScheduledPost : handleSchedulePost}>*/}
    {/*  {isScheduleButtonLoading*/}
    {/*    ? `Loading...`*/}
    {/*    : (hasScheduledPost*/}
    {/*      ? `Cancel Scheduled Post`*/}
    {/*      : `Schedule post on Facebook`*/}
    {/*    )}*/}
    {/*</Button>*/}
    {handleDeleteArticle && (
      <Button accent fullWidth topSpace onPress={handleDeleteArticle}>
        {`Delete this ${articleType}`}
      </Button>
    )}
    <ButtonLink primary fullWidth topSpace isNewTab to={toListUrl}>
      {`Go to ${articleType} list`}
    </ButtonLink>
  </Section>
)

export default ArticleAdminButtons
