import React, { Component } from "react"

import isMobile from "../../components/primitives/isMobile"
import { Link } from "../../compat/Router"
import ListViewScrollToTopOnRouteChange from "../primitives/ListViewScrollToTopOnRouteChange"
import PlaceListItem from "../PlaceListItem"

class PlaceList extends Component {
  state = {}

  renderPlaceListItem = ({ item: props, style }) => {
    return props ? (
      <Link key={props.key} to={`/place/${props.key}`}>
        <PlaceListItem className="content-padded" {...props} {...{ style }} />
      </Link>
    ) : (
      <PlaceListItem className="content-padded" {...{ style }} />
    )
  }

  render() {
    const { markers, places, listKey, isMobile } = this.props
    const data = markers.map((marker) => {
      const { key } = marker
      const place = places[key]
      return {
        key,
        place,
        isMobile,
        marker,
      }
    })
    return (
      <ListViewScrollToTopOnRouteChange
        {...this.props}
        listKey={listKey}
        data={data}
        renderItem={this.renderPlaceListItem}
      />
    )
  }
}

export default isMobile(PlaceList)
