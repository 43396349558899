import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "./index.css"

const TabBar = ({ titles, selectedIndex, onSelectIndex, renderTitle, ...props }) => {
  return (
    <Tabs selectedIndex={selectedIndex} onSelect={onSelectIndex}>
      <TabList>
        {titles.map((x, i) => (
          <Tab key={i}>{renderTitle(x)}</Tab>
        ))}
      </TabList>
      {titles.map((x, i) => (
        <TabPanel key={i} />
      ))}
    </Tabs>
  )
}

export default TabBar
