import React from "react"
import Tag from "../Tag"
import Layout from "../../primitives/Layout"
// import platformStyles from './styles'

const TagList = ({ tags, onPressTag, ...props }) => (
  <Layout wrap {...props}>
    <Layout horizontal htmlTag="ul" wrap style={styles.tags}>
      {tags.map((tag) => (
        <Tag
          key={tag.id}
          style={styles.tag}
          enabled={tag.selected}
          onPress={() => {
            if (onPressTag) onPressTag(tag)
          }}
        >
          {tag.name}
        </Tag>
      ))}
    </Layout>
  </Layout>
)

const styles = {
  tags: {
    marginRight: -10,
    marginBottom: -10,
  },
  tag: {
    marginRight: 10,
    marginBottom: 10,
  },
}

export default TagList
