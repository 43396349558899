import React from "react"

import { Link } from "../../../compat/Router"
import Button from "../Button"

const ButtonLink = ({ children, to, isNewTab, fullWidth, ...props }) => {
  const linkProps = {}
  if (isNewTab) linkProps.target = "_blank"
  const styleLink = { display: "flex" }
  if (fullWidth) {
    styleLink.flex = 1
  }
  return (
    <Link to={to} {...linkProps} style={styleLink}>
      <Button {...props} {...{ fullWidth }} onPress={null}>
        {children}
      </Button>
    </Link>
  )
}

export default ButtonLink
