import React from "react"

import ArticleDetail from "../ArticleDetail"
import PlaceAdmin from "../PlaceAdmin"
import Layout from "../primitives/Layout"
import isMobile from "../primitives/isMobile"

const PlaceRender = ({
  place,
  user,
  placeRefUrl,
  placeFileRefUrl,
  handleDeletePlace,
  ...props
}) => {
  if (place) {
    const { isMobile, tags } = props
    return (
      <Layout className="content-padded">
        <ArticleDetail
          {...props}
          article={place}
          articleType="place"
          articleRefUrl={placeRefUrl}
          articleFileRefUrl={placeFileRefUrl}
          editView={
            <PlaceAdmin
              marginHalf={isMobile}
              topSpace={!isMobile}
              {...{ place, tags, handleDeletePlace }}
            />
          }
        />
      </Layout>
    )
  }
  return null
}

export default isMobile(PlaceRender)
