import React from "react"

import { open } from "../../utils/UrlOpener"
// import Colors from '../../const/Colors'

import Card from "../primitives/Card"
import Text from "../primitives/Text"
import Icon from "../primitives/Icon"
import Assets from "../../const/Assets"

// import ImageAvatar from '../ImageAvatar'

// const avatarSize = 22
const iconSize = 22

const Author = ({ name, avatarUrl, instagramUrl, facebookUrl, prefix, ...props }) => {
  return (
    <Card paddingHalf horizontal centerVertical {...props}>
      {/* {avatarUrl && <ImageAvatar imageSize={avatarSize} imageUrl={avatarUrl} userName={name} />} */}
      <IconSocialMedia imageUrl={Assets.ICON_FACEBOOK} linkUrl={facebookUrl} />
      <IconSocialMedia imageUrl={Assets.ICON_INSTAGRAM} linkUrl={instagramUrl} />
      <Text paddingHalf soft small>
        {prefix}
        {name}
      </Text>
    </Card>
  )
}

const IconSocialMedia = ({ imageUrl, linkUrl, ...props }) =>
  linkUrl ? (
    <Icon source={imageUrl} size={iconSize} paddingHalf onPress={() => open(linkUrl, true)} />
  ) : null

Author.defaultProps = {
  prefix: `Author: `,
}

export default Author
