import React, { Component } from "react"

import ArticleListAdmin from "../../components/ArticleListAdmin"
import Layout from "../../components/primitives/Layout"

class AdminPlaceListScreen extends Component {
  state = {}

  render() {
    const { placesListFiltered: articlesList } = this.props
    return (
      <Layout scrollable className="content-padded">
        <ArticleListAdmin
          {...this.props}
          {...this.state}
          {...{ articlesList }}
          // onSelectedArticle={this.onSelectedArticle}
          articleName="Place"
          articlePath="place"
        />
      </Layout>
    )
  }
}

export default AdminPlaceListScreen
