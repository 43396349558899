import React, { Component } from "react"

import MapScreenRender from "./render"

class MapScreen extends Component {
  state = {}

  onMapMounted = (ref) => {}

  render() {
    return <MapScreenRender {...this.props} {...this.state} onMapMounted={this.onMapMounted} />
  }
}

export default MapScreen
