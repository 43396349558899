import React from "react"

import Filter from "../Filter"
import Logo from "../Logo"
import Layout from "../primitives/Layout"
import Icon from "../primitives/Icon"
import isMobile from "../primitives/isMobile"
import Link from "../primitives/Link"
import { Switch, Route } from "../../compat/Router"

import { buildNavigationLink } from "../../utils/PathUtils"
import Colors from "../../const/Colors"
import { PADDING_HALF } from "../../const/Dimensions"
import Assets from "../../const/Assets"

const HeaderRouter = ({
  goToPlaces,
  children,
  layoutProps,
  tagsList,
  selectedTag,
  handleSelectedTag,
  ...props
}) => {
  const {
    location: { pathname },
  } = props
  const firstPathnameSplit = pathname && pathname.split("/")
  const firstPathname = firstPathnameSplit && firstPathnameSplit.length > 1 && firstPathnameSplit[1]
  const showPlaceTagsList =
    firstPathname && tagsList && ["map", "list", "place", "admin"].indexOf(firstPathname) !== -1

  return (
    <Layout htmlTag="header" style={s.header} {...layoutProps}>
      <Layout horizontal spaceBetween centerVertical style={s.navigation}>
        <LeftView {...props} />
        <Logo source={Assets.IMG_BRAND_LOGO} />
        <RightView {...props} />
      </Layout>
      {showPlaceTagsList && tagsList ? (
        <Filter
          tagsList={tagsList}
          selectedTag={selectedTag}
          onSelectTag={handleSelectedTag}
        />
      ) : null}
      {children}
    </Layout>
  )
}

const IconWithProps = ({ image, to }) => {
  if (!to) return <Icon padding />
  const target = to.startsWith("http") ? "_blank" : null
  return (
    <Link to={to} target={target}>
      <Icon source={image} padding />
    </Link>
  )
}

const IconSwitch = ({ position, isMobile, routerPaths, ...props }) => {
  return (
    <Switch>
      {routerPaths.map(({ path, exact, ...icons }) => {
        const icon = icons[position]
        const hideOnDesktop = icon && icon.hideOnDesktop
        const hide = hideOnDesktop && !isMobile
        const image = !hide && icon && icon.image
        const to = !hide && icon && buildNavigationLink(props, icon)
        return (
          <Route
            exact={exact}
            path={path}
            key={path}
            render={(x) => <IconWithProps to={to} image={image} />}
          />
        )
      })}
    </Switch>
  )
}

const LeftView = (props) => (
  <Layout wrap horizontal>
    <IconSwitch {...props} position="left" />
    <IconSwitch {...props} position="left2" />
    <IconSwitch {...props} position="left3" />
    <IconSwitch {...props} position="left4" />
  </Layout>
)

const RightView = (props) => (
  <Layout wrap horizontal>
    <IconSwitch {...props} position="right4" />
    <IconSwitch {...props} position="right3" />
    <IconSwitch {...props} position="right2" />
    <IconSwitch {...props} position="right" />
  </Layout>
)

const s = {
  header: {
    backgroundColor: "#fff",
    zIndex: 0,
    width: "100%",
    borderBottomStyle: "solid",
    borderBottomWidth: PADDING_HALF,
    borderBottomColor: Colors.accent,
  },
  layoutRight: {
    alignItems: "flex-end",
  },
  image: {},
}

export default isMobile(HeaderRouter)
