import React from "react"

import Layout from "../primitives/Layout"
import MultiSelector from "../primitives/MultiSelector"

const PlaceMultiSelectorRender = ({
  value,
  options,
  onChange,
  onRemoveFromSelection,
  onAddToSelection,
  ...props
}) => (
  <Layout {...props}>
    <MultiSelector
      name="form-field-places"
      {...{
        value,
        options,
        onChange,
        onAddToSelection,
        onRemoveFromSelection,
      }}
    />
  </Layout>
)

PlaceMultiSelectorRender.defaultProps = {}

export default PlaceMultiSelectorRender
