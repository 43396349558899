import React, {Component} from "react"

import {database} from "../../firebaseApp"

import TagAdminRender from "./render"

class TagAdmin extends Component {
  UNSAFE_componentWillMount() {
    const {selectedTag} = this.props
    this.setRefs(selectedTag)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {selectedTag} = nextProps
    this.setRefs(selectedTag)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe() {
    this.editorId = null
    if (this.visibilityRef) this.visibilityRef.off()
    if (this.editorRef) this.editorRef.off()
    if (this.orderPositionRef) this.orderPositionRef.off()
  }

  setRefs(selectedTag) {
    if (!selectedTag || selectedTag.id !== this.selectedTagId) {
      this.unsubscribe()
      this.selectedTagId = selectedTag ? selectedTag.id : null
      if (this.visibilityRef) this.visibilityRef.off()
      if (this.isSponsored) this.isSponsored.off()
      if (selectedTag) {
        this.tagRefUrl = `tags/zecelarece/${selectedTag.id}`
        this.tagFileRefUrl = `image/zecelarece/tag_cover/${selectedTag.id}.jpg`
        const tagRef = database.ref(this.tagRefUrl)
        this.orderPositionRef = tagRef.child("order")
        this.orderPositionRef.on("value", (snap) => {
          this.setState({orderPosition: snap.val()})
        })
        this.sponsoredUrlRef = tagRef.child("sponsoredUrl")
        this.sponsoredUrlRef.on("value", (snap) => {
          this.setState({sponsoredUrl: snap.val()})
        })
        this.editorRef = tagRef.child("editorId")
        this.editorRef.on("value", (snap) => {
          const valueEditors = snap.val()
          this.setState({valueEditors})
        })
        this.visibilityRef = tagRef.child("visibility")
        this.visibilityRef.on("value", (snap) => {
          this.setState({visibility: snap.val()})
        })
        this.isSponsoredRef = tagRef.child("isSponsored")
        this.isSponsoredRef.on("value", (snap) => {
          this.setState({isSponsored: snap.val()})
        })
      } else {
        this.tagRefUrl = null
        this.tagFileRefUrl = null
      }
    }
  }

  handleChangePosition = (value) => {
    if (this.orderPositionRef) this.orderPositionRef.set(parseInt(value, 10))
  }

  handleChangeSponsoredUrl = (value) => {
    if (this.sponsoredUrlRef) this.sponsoredUrlRef.set(value)
  }

  handleChangeEditor = ({value, label}) => {
    if (this.editorRef) this.editorRef.set(value)
  }

  onValueChangeVisibility = (value) => {
    this.visibilityRef.set(value)
  }

  onValueChangeIsSponsored = (value) => {
    this.isSponsoredRef.set(value)
  }

  handleDeleteTag = async () => {
    const {places, selectedTag} = this.props
    const {id: tagId, name: tagName} = selectedTag
    const areYouSureText = `Are you sure you want to delete the tag ${tagName}?\nEverything about it will be lost, there's no going back.\n\nPS: It is said that if your name is Luka, destroying useless data brings good luck.`
    const errorMessage = `Something went wrong, let a Gabriel know.`
    const successMessage = `Successful deletion. Goodbye ${tagName}! You'll be missed`
    if (window.confirm(areYouSureText)) {
      try {
        if (this.tagRefUrl) {
          const removePromises = [database.ref(this.tagRefUrl).remove()]
          Object.keys(places).forEach((placeId) => {
            const placeTagRefToRemove = `places/zecelarece/${placeId}/tags/${tagId}`
            removePromises.push(database.ref(placeTagRefToRemove).remove())
          })
          await Promise.all(removePromises)
          alert(successMessage)
          window.location.replace("/admin/tags?source=map")
        } else {
          alert(errorMessage + "\n\nNO TAG REF URL!!")
        }
      } catch (err) {
        console.error(err)
        alert(errorMessage)
      }
    }
  }

  render() {
    const {selectedTag} = this.props
    const tagName = selectedTag && selectedTag.name
    const addPlaceTitle = tagName ? `Add or remove places to '${tagName}'` : null

    return (
      <TagAdminRender
        {...this.state}
        {...this.props}
        handleChangePosition={this.handleChangePosition}
        handleChangeEditor={this.handleChangeEditor}
        handleDeleteTag={this.handleDeleteTag}
        handleChangeSponsoredUrl={this.handleChangeSponsoredUrl}
        addPlaceTitle={addPlaceTitle}
        onValueChangeVisibility={this.onValueChangeVisibility}
        onValueChangeIsSponsored={this.onValueChangeIsSponsored}
      />
    )
  }
}

export default TagAdmin
