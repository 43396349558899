import React from "react"
import { Link } from "../../../compat/Router"

const ZLink = ({ to, children, ...props }) => {
  if (to && to.startsWith("http")) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}

export default ZLink
