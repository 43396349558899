import React from "react"

import Layout from "../primitives/Layout"

import ArticleItem from "../ArticleItem"
import { getFormattedDistanceFromValue } from "../Distance/utils"

const PlaceListItemRender = ({
  place,
  style,
  isMobile,
  onPressArticle,
  articleEmpty,
  handleMarkerClick,
  ...props
}) => {
  if (!place) return null
  const { visibility, distance, id: placeId, cover } = place
  return (
    <Layout
      onPress={onPressArticle}
      bottomSpace
      topSpace
      softer={!visibility}
      {...props}
      style={{ ...style, ...{ overflow: "hidden" } }}
    >
      <ArticleItem
        isMobile={isMobile}
        editable={false}
        article={place}
        value={getFormattedDistanceFromValue(distance)}
        refUrl={`places/zecelarece/${placeId}`}
        fileRefUrl={`image/zecelarece/place_cover/${placeId}.jpg`}
        placeholderName="Add a place name"
        placeholderDescription="Add a place description"
        cover={cover}
        imageSize={isMobile ? "small" : "big"}
        defaultDescription={articleEmpty}
        // state={{name, description, cover}}
      />
    </Layout>
  )
}

export default PlaceListItemRender
