import React from "react"

import SectionTitle from "../SectionTitle"
import Layout from "../primitives/Layout"
import isMobile from "../primitives/isMobile"
import MultiSelector from "../primitives/MultiSelector"

const TagMultiSelectorRender = ({ tagProps }) => (
  <Layout>
    <SectionTitle>Edit tags</SectionTitle>
    <MultiSelector paddingHalf name="form-field-tags" {...tagProps} />
  </Layout>
)

TagMultiSelectorRender.defaultProps = {}

export default isMobile(TagMultiSelectorRender)
