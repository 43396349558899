import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/storage"

import { FirebaseConfig } from "./config"

firebase.initializeApp(FirebaseConfig[process.env.REACT_APP_APP_NAME].prod)

// export const provider = new firebase.auth.GoogleAuthProvider()

export const auth = firebase.auth()
export const database = firebase.database()
export const storage = firebase.storage && firebase.storage()

export default firebase
