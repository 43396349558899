/* global google */
// allows you to use google, google.map...

import React from "react"
import { Marker, InfoWindow } from "react-google-maps"

import Button from "../../../primitives/Button"
import Text from "../../../primitives/Text"
import Layout from "../../../primitives/Layout"

import { ICON_SIZE } from "../../const"
import Assets from "../../../../const/Assets"

const SearchMarkerRender = ({
  marker,
  place,
  onCloseInfoWindow,
  onPressAddPlace,
  onPressEditPlace,
  onPressReplacePlace,
  useReplaceMode,
  tags,
  ...props
}) => {
  if (!marker) return null
  const { name, position } = marker
  const { address, website, id, visibility } = place
  return (
    <Marker
      key="searchMarker"
      position={position}
      title={name}
      icon={{
        url: visibility ? Assets.IMG_MARKER_VISIBLE : Assets.IMG_MARKER_HIDDEN,
        scaledSize: new google.maps.Size(ICON_SIZE.selected, ICON_SIZE.selected),
      }}
      clickable={false}
    >
      <InfoWindow onCloseClick={onCloseInfoWindow}>
        <Layout paddingHalf>
          <Text paddingHalf bold>
            {name + (website ? ` (${website})` : "")}
          </Text>
          <Text paddingHalf soft>
            {address}
          </Text>
          {!id && !useReplaceMode && (
            <Layout>
              <Button accent style={{ margin: 5 }} onPress={() => onPressAddPlace(place)}>
                Add place
              </Button>
            </Layout>
          )}
          {!id && useReplaceMode && (
            <Layout>
              <Button accent style={{ margin: 5 }} onPress={() => onPressReplacePlace(place)}>
                Use this google place instead
              </Button>
            </Layout>
          )}
          {id && (
            <Button accent style={{ margin: 5 }} onPress={() => onPressEditPlace(place)}>
              Edit place
            </Button>
          )}
        </Layout>
      </InfoWindow>
    </Marker>
  )
}

export default SearchMarkerRender
