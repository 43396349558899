import React from "react"
import Text from "../../primitives/Text"
import Card from "../../primitives/Card"
import Colors from "../../../const/Colors"
import platformStyles from "./styles"

const Tag = ({ children, enabled, ...props }) => (
  <Card htmlTag="li" {...props}>
    <Text
      small
      wrap
      style={[styles.default, platformStyles.default, enabled ? null : styles.disabled]}
    >
      {children}
    </Text>
  </Card>
)

const styles = {
  default: {
    color: Colors.accent,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    // borderWidth: 1,
    // borderColor: Colors.accent,
    // borderRadius: 14,
  },
  disabled: {
    color: Colors.neutral,
    // borderColor: Colors.neutral,
  },
}

export default Tag
