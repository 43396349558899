import URLSearchParams from "url-search-params" // polyfill to support react native

export const DEFAULT_BASE_PATH = "map"
export const DEFAULT_TAG_ID = "all"

export const getBasePathFromUrl = (url) => {
  const urlParts = (url.indexOf("/") !== -1 && url.split("/").filter((x) => x !== "")) || [url]
  const basePath =
    urlParts.length === 0
      ? DEFAULT_BASE_PATH
      : (!urlParts[0] || urlParts[0] === "") && urlParts.length > 1
      ? urlParts[1] // if url starts with / the first item will be empty
      : urlParts[0]
  return basePath
}

export const getBasePathFromProps = ({ location: { pathname } }) => {
  // const url = pathname + search
  return getBasePathFromUrl(pathname)
}

export const makePathRelative = (path) => {
  return (
    path &&
    path
      .split("/")
      .filter((x) => x !== "")
      .join("/")
  )
}

export const getSelectedTagIdFromProps = (props) => {
  return getQueryParamsValueFromProps(props).tagId
}

export const getQueryParamsValueFromSearch = (search) => {
  const searchParams = new URLSearchParams(search)
  if (searchParams) {
    const tagId = searchParams.get("tagId") //|| DEFAULT_TAG_ID
    const source = searchParams.get("source") || null
    return { tagId, source }
  }
  return {}
}

export const getQueryParamsValueFromProps = ({ location: { search } }) => {
  return getQueryParamsValueFromSearch(search)
}

export const buildNavigationLink = (props, description) => {
  if (!description) return "/"
  let { to } = description
  const { keepQuery, trackSource } = description
  if (to && to.indexOf("?source") !== -1) {
    const { source } = getQueryParamsValueFromProps(props)
    to = to.replace("?source", source != null && source !== "null" ? source : "")
  }
  if (trackSource || keepQuery) {
    const { pathname } = props.location
    const source = (trackSource && { source: makePathRelative(pathname) }) || {}
    let prevQueryParams = (keepQuery && getQueryParamsValueFromProps(props)) || {}
    delete prevQueryParams.source // don't remember source when go back
    let queryParams = { ...prevQueryParams, ...source }
    to += `?${buildQueryString(queryParams)}`
  }
  return to
}

export const buildNavigationLinkWithMemory = (props, to) => {
  return buildNavigationLink(props, {
    to,
    keepQuery: true,
    trackSource: true,
  })
}

export const buildNavigationLinkWithSource = (props, to) => {
  return buildNavigationLink(props, {
    to,
    keepQuery: false,
    trackSource: true,
  })
}

export const buildNavigationLinkWithQuery = (props, to) => {
  return buildNavigationLink(props, {
    to,
    keepQuery: true,
    trackSource: false,
  })
}

export const buildQueryString = (params) => {
  const esc = encodeURIComponent
  const query = Object.keys(params)
    .filter((k) => params[k] != null && params[k] !== undefined)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&")
  return query
}

export const makeIdFromName = (name) => {
  return (
    name &&
    name
      .trim() // remove spaces around
      .toLowerCase() // set to lower case
      .replace(/[^a-zA-Z ]/g, "") // remove all weird characters but keep spaces
      .replace(/ +(?= )/g, "") // remove potentatial duplicate spaces between the words
      .replace(/ /g, "-")
  ) // turn those last spaces between the words in dashes
}

export const makeUniqueIdFromName = (name) => makeIdFromName(name)
