import React, { Component } from "react"

import { database } from "../../firebaseApp"

import ListScreenRender from "./render"

class ListScreen extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.setRefs(this.props)
  }

  setRefs(props) {
    const { selectedTag } = props
    const selectedTagId = (selectedTag && selectedTag.id) || null
    if (this.selectedTagId !== selectedTagId) {
      this.selectedTagId = selectedTagId
      if (this.visibilityRef) this.visibilityRef.off()
      if (selectedTagId) {
        this.tagRefUrl = `tags/zecelarece/${selectedTagId}`
        this.tagFileRefUrl = `image/zecelarece/tag_cover/${selectedTagId}.jpg`
        const tagRef = database.ref(this.tagRefUrl)
        this.visibilityRef = tagRef.child("visibility")
        this.visibilityRef.on("value", (snap) => {
          this.setState({ visibility: snap.val() })
        })
      } else {
        this.tagRefUrl = null
        this.tagFileRefUrl = null
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setRefs(nextProps)
  }

  onValueChangeVisibility = (value) => {
    this.visibilityRef.set(value)
  }

  render() {
    const { selectedTag } = this.props
    const tagName = selectedTag && selectedTag.name
    const addPlaceTitle = tagName ? `Add or remove places to '${tagName}'` : null

    return (
      <ListScreenRender
        {...this.props}
        {...this.state}
        addPlaceTitle={addPlaceTitle}
        tagRefUrl={this.tagRefUrl}
        tagFileRefUrl={this.tagFileRefUrl}
        onValueChangeVisibility={this.onValueChangeVisibility}
      />
    )
  }
}

export default ListScreen
