import React from "react"

import Colors from "../../const/Colors"

import Layout from "../primitives/Layout"
import Text from "../primitives/Text"

const Section = ({ style, dividers, topDivider, bottomDivider, children, title, ...props }) => (
  <Layout
    paddingHalf
    {...props}
    style={{
      ...styles.layout,
      borderTopWidth: dividers || topDivider ? 1 : 0,
      borderBottomWidth: dividers || bottomDivider ? 1 : 0,
      ...style,
    }}
  >
    {title && (
      <Text small bold accent paddingHalf>
        {title}
      </Text>
    )}
    {children}
  </Layout>
)

const styles = {
  layout: {
    // backgroundColor: "rgba(0, 0, 0, 0.03)",
    borderStyle: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: Colors.divider,
  },
}

Section.defaultProps = {
  dividers: false,
  topDivider: false,
  bottomDivider: false,
}

export default Section
