import React, { Component } from "react"
import { injectStylesInProps } from "./functions"

export default function StylesReader(WrappedScreen) {
  return class extends Component {
    setNativeProps = (nativeProps) => {
      this._root.setNativeProps(nativeProps)
    }

    render() {
      const props = injectStylesInProps(this.props)
      return (
        <WrappedScreen
          {...props}
          // _onNativeViewMounted={component => this._root = component}
        />
      )
    }
  }
}
