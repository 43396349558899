import React, { Component } from "react"
import PropTypes from "prop-types"

import { database } from "../../firebaseApp"

import ArticleItemRender from "./render"
import isMobile from "../primitives/isMobile"

class ArticleItem extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.onComponentUpdate(this.props)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.onComponentUpdate(nextProps)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe() {
    if (this.descriptionRef) this.descriptionRef.off()
    if (this.nameRef) this.nameRef.off()
    if (this.visibilityRef) this.visibilityRef.off()
    if (this.editorIdRef) this.editorIdRef.off()
    if (this.editorRef) this.editorRef.off()
    if (this.hasUsedAiGenerationOnceRef) this.hasUsedAiGenerationOnceRef.off()
  }

  setRefs(refUrl) {
    if (this.refUrl !== refUrl) {
      this.refUrl = refUrl
      // const { articlePrefetch } = this.state
      // if (articlePrefetch && refUrl && refUrl.indexOf(articlePrefetch.id) !== -1) { // prefetch data from server on first load, don't if id doesn't match prefetch dath
      //   const { name, description, cover } = articlePrefetch
      //   this.setState({ name, description, cover })
      // } else {
      // }
      this.setState({ name: "", description: "", cover: null })
      this.unsubscribe()
      const ref = database.ref(refUrl)
      this.descriptionRef = ref.child("description")
      this.nameRef = ref.child("name")
      this.visibilityRef = ref.child("visibility")
      this.editorIdRef = ref.child(`editorId`)
      this.hasUsedAiGenerationOnceRef = ref.child(`hasUsedAiGenerationOnce`)
      this.descriptionRef.on("value", (snap) => {
        const description = snap.val()
        if (description && description.length > 0) this.setState({ description })
      })
      this.nameRef.on("value", (snap) => {
        this.setState({ name: snap.val() })
      })
      this.visibilityRef.on("value", (snap) => {
        this.setState({ visibility: snap.val() })
      })
      this.hasUsedAiGenerationOnceRef.on("value", (snap) => {
        this.setState({ hasUsedAiGenerationOnce: snap.val() })
      })

      const { showEditor } = this.props
      if (showEditor) {
        this.editorIdRef.on("value", (snap) => {
          const editorId = snap.val()
          this.editorRef = database.ref(`admins/zecelarece/${editorId}`)
          this.editorRef.on("value", (snap) => {
            this.setState({ editor: snap.val() })
          })
        })
      }
    }
  }

  onComponentUpdate = (props) => {
    const { refUrl } = props
    this.setRefs(refUrl)
  }

  onDescriptionEdited = (text) => {
    // todo: should we do some string validation before sending the change ?
    this.descriptionRef.set(text) //.then(() => { })
  }

  onNameEdited = (text) => {
    this.nameRef.set(text) //.then(() => { })
  }

  onChangeVisibility = (visibility) => {
    this.visibilityRef.set(visibility) //.then(() => { })
  }

  render() {
    const { editable, article } = this.props
    return (
      <ArticleItemRender
        {...this.props}
        {...this.state}
        {...(!editable && article)}
        onNameEdited={this.onNameEdited}
        onDescriptionEdited={this.onDescriptionEdited}
        onChangeVisibility={this.onChangeVisibility}
        onSelectFile={this.onSelectFile}
      />
    )
  }
}

ArticleItem.propTypes = {
  editable: PropTypes.bool.isRequired,
  refUrl: PropTypes.string,
  fileRefUrl: PropTypes.string,
}

export default isMobile(ArticleItem)
