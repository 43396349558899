import React from "react"

import AdminSelector from "../AdminSelector"
import isMobile from "../primitives/isMobile"
import Layout from "../primitives/Layout"
import Text from "../primitives/Text"
import ArticleFinder from "../ArticleFinder"

const ArticleListAdminRender = ({
  isMobile,
  valueAdmin,
  handleChangeAdmin,
  articlesList,
  articleName,
  onSelectedArticle,
  count,
  articlePath,
}) => {
  return (
    <Layout>
      <Layout paddingHalf={!isMobile}>
        <Text medium bold accent padding={isMobile} bottomSpace={!isMobile}>
          Filter by admin
        </Text>
        <Layout padding={isMobile} bottomSpace={!isMobile}>
          <AdminSelector
            style={{ flex: 1 }}
            value={valueAdmin}
            onChange={handleChangeAdmin}
            additionalOptions={[{ value: "unassigned_articles", label: "Unassigned" }]}
          />
        </Layout>
      </Layout>
      <ArticleFinder
        filterKey={valueAdmin}
        title={`${count.total} ${articleName}s`}
        description={`${count.visible} visible ${count.done} done`}
        paddingHalf={isMobile}
        bottomSpace={!isMobile}
        suggestions={articlesList}
        onSelectedArticle={onSelectedArticle}
        alwaysRenderSuggestions={true}
        articlePath={articlePath}
      />
    </Layout>
  )
}

export default isMobile(ArticleListAdminRender)
