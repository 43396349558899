import React, { Component } from "react"
import ChildLayout from "./Layout"
import StylesReader from "../../../hoc/StylesReader"

class Layout extends Component {
  render() {
    return <ChildLayout {...this.props} />
  }
}

export default StylesReader(Layout)
