import React from "react"
import Text from "../primitives/Text"

const SectionTitle = ({ children, ...props }) => (
  <Text small bold soft paddingHalf topSpace {...props}>
    {children}
  </Text>
)

export default SectionTitle
