import React, { Component } from "react"

import { database } from "../../../firebaseApp"

import MapSearchRender from "./render"
import { withRouter } from "../../../compat/Router"

class MapSearch extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.placesRef = database.ref(`places/zecelarece`)
  }

  onSearchBoxMounted = (searchBox) => {
    this.searchBox = searchBox
  }

  onPlacesChanged = () => {
    if (this.searchBox) {
      const places = this.searchBox.getPlaces()
      console.log('places', places)
      const googlePlace = places.length > 0 ? places[0] : null
      if (googlePlace && googlePlace.geometry) {
        const {
          place_id: googleId,
          geometry: {
            location: { lat, lng },
          },
          name,
          formatted_address,
          website,
        } = googlePlace
        const position = {
          lat: lat(),
          lng: lng(),
        }
        const marker = {
          key: googleId,
          name,
          position,
        }
        console.log('calling placeRef')
        this.placesRef
          .orderByChild("googleId")
          .equalTo(googleId)
          .once("value")
          .then((snap) => {
            console.log('placeRef answer')
            let place
            if (snap.exists()) {
              snap.forEach((child) => {
                place = child.val()
              })
            } else {
              console.log('placeRef snap not existing')
              place = {
                name,
                googleId,
                position,
                visibility: false,
              }
              if (formatted_address) place.address = formatted_address
              if (website) place.website = website
            }
            this.setState({
              marker,
              place,
              searchValue: `${name}, ${formatted_address}`,
            })
            this.onSearchMarkerChanged(marker)
          })
      } else {
        this.setState({ marker: null, place: null })
        this.onSearchMarkerChanged(null)
      }
    } else {
      this.setState({ marker: null, place: null })
      this.onSearchMarkerChanged(null)
      console.warn("onPlacesChanged: WARNING: searchBox not mounted")
    }
  }

  onPressExitSearch = () => {
    this.setState({ marker: null, searchValue: "" })
    this.onSearchMarkerChanged(null)
  }

  onPressAddPlace = (place) => {
    const { user, userId } = this.props
    const id = this.placesRef.push().key
    const editorId = (user && user.uid) || userId
    // console.log('add place', place)
    this.placesRef.child(id).set({ ...place, id, editorId }, () => {
      this.props.goToPlaceId(id)
    })
  }

  onPressReplacePlace = (googlePlace) => {
    const { placeMarker } = this.props
    console.log("onPressReplacePlace place", googlePlace)
    console.log("onPressReplacePlace placeMarker", placeMarker)
    const { id: placeId } = placeMarker
    if (!googlePlace.website) {
      delete googlePlace.website
    }
    this.placesRef
      .child(placeId)
      .update(googlePlace)
      .then(() => {
        window.location.reload()
      })
  }

  onPressEditPlace = (place) => {
    if (place) {
      this.props.goToPlaceId(place.id)
    }
  }

  onSearchValueChanged = (e) => {
    this.setState({ searchValue: e.target.value })
  }

  onSearchMarkerChanged = (marker) => {
    const { onSearchMarkerChanged } = this.props
    if (onSearchMarkerChanged) onSearchMarkerChanged(marker)
  }

  render() {
    return (
      <MapSearchRender
        {...this.props}
        {...this.state}
        onSearchBoxMounted={this.onSearchBoxMounted}
        onPlacesChanged={this.onPlacesChanged}
        onSearchValueChanged={this.onSearchValueChanged}
        onCloseInfoWindow={this.onPressExitSearch}
        onPressAddPlace={this.onPressAddPlace}
        onPressReplacePlace={this.onPressReplacePlace}
        onPressEditPlace={this.onPressEditPlace}
      />
    )
  }
}

export default withRouter(MapSearch)
