import React from "react"
import moment from "moment"

import ImageCoverUploader from "../ImageCoverUploader"
import ArticleTagList from "../Tags/ArticleTagList"
import Distance from "../Distance"
import Author from "../Author"
import Text from "../primitives/Text"
import Layout from "../primitives/Layout"
import Button from "../primitives/Button"
import isMobile from "../primitives/isMobile"
import Map from "../Map"
import s from "./styles"
import PositionEditor from "../PositionEditor"

const ArticleDetailRender = ({
  isMobile,
  article,
  articleType,
  articleRefUrl,
  articleFileRefUrl,
  name,
  description,
  hasUsedAiGenerationOnce,
  website,
  editor,
  children,
  footerView,
  editView,
  onNameEdited,
  onDescriptionEdited,
  onPressLearnMore,
  onPressGoThere,
  marker,
  markers,
  userLocation,
  handleMarkerClick,
  authorPrefix,
  onUploadedNewCover,
  onClickDirection,
  onClickCancel,
  onClickValidate,
  isEditingPosition,
  canEditPosition,
  onPressGoogleUpdate,
  goToPlaceId,
}) => {
  if (article) {
    const { address, placeName, tags: articleTags, id: articleId, distance, startTime } = article
    const addressArray = address.split(", Buc")
    const addressShort = addressArray.length > 0 ? addressArray[0] : address
    const dateFormatted = startTime && moment(startTime).format("dddd, MMMM Do")
    return (
      <Layout style={{ flex: 1 }}>
        <Layout bottomSpace className="article-header" style={s.layoutHeader}>
          <Layout style={s.layoutPictureWithTitle}>
            <Map
              goToPlaceId={goToPlaceId}
              height={isMobile ? 180 : 220}
              marker={marker}
              markers={markers}
              userLocation={userLocation}
              onMarkerClick={handleMarkerClick}
              showSearch
              searchPlaceholder="Change the google place"
              useReplaceMode
            />
            <Layout horizontal style={s.layoutTitleBar}>
              <Layout vertical padding style={s.layoutTitleContent}>
                <Text
                  mediumBig
                  bold
                  editable={true}
                  editKey={articleId}
                  onTextEdited={onNameEdited}
                  placeholder={`Add a ${articleType} name`}
                >
                  {name}
                </Text>
                <Layout horizontal centerVertical>
                  <Text small soft>
                    {!!placeName ? `${placeName}, ${addressShort}` : addressShort}
                  </Text>
                  {distance && <Distance value={distance} leftSpace />}
                </Layout>
                {dateFormatted && (
                  <Text small soft>
                    {dateFormatted}
                  </Text>
                )}
              </Layout>
              <Layout horizontal style={s.leftMenu}>
                {canEditPosition && (
                  <>
                    <Button accent onPress={onPressGoogleUpdate}>
                      Google
                      <br />
                      Map
                      <br />
                      Update
                    </Button>
                    <PositionEditor
                      style={s.positionEditor}
                      onClickDirection={onClickDirection}
                      onClickCancel={onClickCancel}
                      onClickValidate={onClickValidate}
                      isEditingPosition={isEditingPosition}
                    />
                  </>
                )}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
        <ImageCoverUploader
          fullScreen
          editable={true}
          refUrl={articleRefUrl}
          fileRefUrl={articleFileRefUrl}
          onUploadedNewCover={onUploadedNewCover}
          placeId={articleId}
        />
        <Layout className="article-body">
          <Layout padding>
            <Text
              small
              editable={true}
              editKey={articleId}
              onTextEdited={onDescriptionEdited}
              placeholder={`Add a ${articleType} description`}
            >
              {description}
            </Text>
            {hasUsedAiGenerationOnce && (<Text xsmall soft topSpace>{`🤖 Article written using AI generation at least once`}</Text>)}
            <ArticleTagList tags={articleTags} topSpace />
          </Layout>
          {children}

          {editor && (
            <Author
              name={editor.name}
              facebookUrl={editor.facebook}
              instagramUrl={editor.instagram}
              prefix={authorPrefix}
            />
          )}
          {footerView}
          <Layout horizontal>
            {
              website ? (
                <Button fullWidth accent onPress={onPressLearnMore}>
                  {`Learn more`}
                </Button>
              ) : null // else crashes in react native
            }
            <Button fullWidth primary onPress={onPressGoThere}>
              {`Go there`}
            </Button>
          </Layout>
          {editView}
        </Layout>
      </Layout>
    )
  }
  return null
}

export default isMobile(ArticleDetailRender)
