import React, { Component } from "react"
import "./index.css"
import { FilePicker } from "react-file-picker"
import ImageGallery from "react-image-gallery"
import Modal from "react-modal"
import Cropper from "react-easy-crop"
import Loading from "../Loading"
import Layout from "../Layout"
import Button from "../Button"
import getCroppedImg from "./utils"
import { PADDING } from "../../../const/Dimensions"
import { open } from "../../../utils/UrlOpener"

const DEFAULT_STATE = {
  fileToCropUrl: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  isModalOpen: false,
  suggestedCovers: null,
  isLoadingSuggestedCovers: false,
  suggestedCoverAuthor: null,
}
Modal.setAppElement("#root")

const AuthorButton = ({ author }) => (
  <Button
    style={{ maxWidth: 250, backgroundColor: "rgba(0, 0, 0, 0.25)" }}
    onPress={() => {
      open(author.url)
    }}
  >
    {author.name}
  </Button>
)

class ImageCover extends Component {
  state = { ...DEFAULT_STATE }
  croppedAreaPixels = null

  onSelectFile = (file) => {
    if (file) {
      this.setState({ fileToCropUrl: URL.createObjectURL(file) })
    }
  }

  setCrop = (crop) => {
    this.setState({ crop })
  }

  setZoom = (zoom) => {
    this.setState({ zoom })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.croppedAreaPixels = croppedAreaPixels
  }

  onPressCancelCrop = () => {
    this.setState({ ...DEFAULT_STATE })
  }

  onPressValidateCrop = async () => {
    const { fileToCropUrl, suggestedCoverAuthor } = this.state
    const croppedImage = await getCroppedImg(fileToCropUrl, this.croppedAreaPixels)
    let croppedImageBlob = await fetch(croppedImage).then((r) => r.blob())
    this.props.onSelectFile({ file: croppedImageBlob, author: suggestedCoverAuthor })
    this.setState({ ...DEFAULT_STATE })
  }

  onAfterOpen = () => {}

  onSelectSuggestedCover = ({ url, author }) => {
    this.setState({ fileToCropUrl: url, suggestedCoverAuthor: author })
  }

  openModal = () => {
    const { placeId } = this.props
    if (placeId) {
      this.setState({ isLoadingSuggestedCovers: true })
      fetch(`${process.env.REACT_APP_API_BASE_URL}/places/get/suggested_covers?placeId=${placeId}`)
        .then((res) => res.json())
        .then(({ data }) => {
          const suggestedCovers = data.suggestedCovers.map(({ url, authors }) => ({
            original: url,
            thumbnail: url,
            authors,
          }))
          this.setState({
            suggestedCovers,
            isModalOpen: true,
            isLoadingSuggestedCovers: false,
          })
        })
        .catch((err) => {
          console.error("error fetching suggested covers", err)
          this.setState({
            isLoadingSuggestedCovers: false,
          })
        })
    }
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  renderItem = (item) => {
    const { original: url, authors } = item
    const author = authors && authors.length && authors[0]
    return (
      <Layout>
        <Layout
          style={{
            position: "absolute",
            top: PADDING,
            right: PADDING,
          }}
        >
          <AuthorButton author={author} />
        </Layout>
        <div
          style={{ backgroundImage: `url('${url}')` }}
          className="img-gallery-img clickable"
          onClick={() => {
            this.onSelectSuggestedCover({ url, author })
          }}
        />
      </Layout>
    )
  }

  render() {
    const {
      // onPress,
      fullScreen,
      imageUrl,
      author,
      onSelectFile,
      editable,
      isLoading,
      placeId,
      style,
      ...props
    } = this.props

    const {
      fileToCropUrl,
      crop,
      zoom,
      isModalOpen,
      suggestedCovers,
      isLoadingSuggestedCovers,
    } = this.state
    const imgStyle = imageUrl ? { backgroundImage: `url('${imageUrl}')` } : null

    const classNameDefault =
      "image-cover" + (fullScreen ? " image-cover-full-screen" : " image-cover-padded")
    let className = classNameDefault
    if (editable) className += " clickable"
    const Image = (
      <Layout {...props}>
        <Layout {...{ className }} style={[imgStyle, style]} />
      </Layout>
    )

    if (isLoading)
      return (
        <Loading className={classNameDefault} spinner show={true}>
          {Image}
        </Loading>
      )

    if (editable) {
      if (fileToCropUrl) {
        return (
          <Layout className={classNameDefault} style={{ position: "relative" }}>
            <Cropper
              style={{ flex: 1 }}
              image={fileToCropUrl}
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={this.setCrop}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.setZoom}
              objectFit="horizontal-cover"
            />
            <Layout horizontal style={{ position: "absolute", bottom: 8, right: 8 }}>
              <Button primary marginHalf onPress={this.onPressCancelCrop}>
                {`Cancel crop`}
              </Button>
              <Button accent marginHalf onPress={this.onPressValidateCrop}>
                {`Validate crop`}
              </Button>
            </Layout>
          </Layout>
        )
      }
      return (
        <>
          <Modal
            isOpen={isModalOpen}
            onAfterOpen={this.onAfterOpen}
            onRequestClose={this.closeModal}
            style={{ zIndex: 999 }}
            contentLabel="Cover suggestions"
          >
            {suggestedCovers && (
              <ImageGallery items={suggestedCovers} renderItem={this.renderItem} />
            )}
          </Modal>
          <Layout style={{ position: "relative" }}>
            {author && (
              <Layout
                style={{
                  position: "absolute",
                  top: PADDING,
                  right: PADDING,
                }}
              >
                <AuthorButton author={author} />
              </Layout>
            )}
            {placeId && (
              <Button
                accent
                style={{ position: "absolute", bottom: 8, right: 8 }}
                onPress={this.openModal}
                enabled={!isLoadingSuggestedCovers}
              >
                {isLoadingSuggestedCovers ? `Loading...` : `Show cover suggestions`}
              </Button>
            )}
            <FilePicker
              extensions={["png", "jpg", "jpeg"]}
              maxSize={4}
              onChange={this.onSelectFile}
              onError={console.error}
            >
              {Image}
            </FilePicker>
          </Layout>
        </>
      )
    }

    return Image
  }
}

export default ImageCover
