import React from "react"

import Text from "../Text"
import Layout from "../Layout"
import SwitchCheckbox from "./SwitchCheckbox"

const Switch = ({ labelEnabled, labelDisabled, value, onValueChange, ...props }) => {
  return (
    <Layout horizontal centerVertical {...props}>
      <SwitchCheckbox value={value} onValueChange={onValueChange} />
      <Text padding small soft={!value} accent={value}>
        {value ? labelEnabled : labelDisabled}
      </Text>
    </Layout>
  )
}

export default Switch
