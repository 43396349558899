import React from "react"

import Colors from "../../const/Colors"

import Text from "../primitives/Text"
import Card from "../primitives/Card"

const SmallValue = ({ children, ...props }) => {
  return (
    <Card backgroundColor={Colors.section} paddingHalf center centerVertical {...props}>
      <Text small black soft>
        {children}
      </Text>
    </Card>
  )
}

export default SmallValue
