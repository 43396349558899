import React, { Component } from "react"
import { database } from "../../firebaseApp"
import UserMultiSelectorRender from "./render"

class UserMultiSelector extends Component {
  state = {}

  UNSAFE_componentWillMount() {
    this.usersRef = database.ref(`users`)
    this.usersRef.orderByChild("displayName").on("value", (snap) => {
      const users = snap.val()
      const options = []
      snap.forEach((snap) => {
        const user = snap.val()
        const { uid, displayName, email } = user
        options.push({ value: uid, label: `${displayName} (${email})` })
      })
      this.setState({ users, options })
    })
  }

  onAddToSelection = (option) => {
    const { onAddToSelection } = this.props
    const user = this.state.users[option.value]
    if (onAddToSelection) onAddToSelection(option, user)
  }

  onRemoveFromSelection = (option) => {
    const { onRemoveFromSelection } = this.props
    const user = this.state.users[option.value]
    if (onRemoveFromSelection) onRemoveFromSelection(option, user)
  }

  render() {
    return (
      <UserMultiSelectorRender
        {...this.state}
        {...this.props}
        {...{
          onAddToSelection: this.onAddToSelection,
          onRemoveFromSelection: this.onRemoveFromSelection,
        }}
      />
    )
  }
}

UserMultiSelector.defaultProps = {}

export default UserMultiSelector
