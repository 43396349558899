import React, { Component } from "react"
import { withRouter } from "../../compat/Router"
import StateManager from "../../hoc/StateManager"

import "react-tabs/style/react-tabs.css"
import "../../components/ArticleDetail/index.css"

import RouterScreenRender from "./render"

class RouterScreen extends Component {
  state = {}

  UNSAFE_componentWillMount() {}

  render() {
    return <RouterScreenRender {...this.props} {...this.state} />
  }
}

export default withRouter(StateManager(RouterScreen))
