import React, { Component } from "react"

import ArticleListAdmin from "../../components/ArticleListAdmin"
import Layout from "../../components/primitives/Layout"

class AdminTagListScreen extends Component {
  state = {}

  render() {
    const { tagsList: articlesList } = this.props
    return (
      <Layout scrollable className="content-padded">
        <ArticleListAdmin
          {...this.props}
          {...this.state}
          {...{ articlesList }}
          articleName="Tag"
          articlePath="tag"
        />
      </Layout>
    )
  }
}

export default AdminTagListScreen
