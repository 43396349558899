import React from "react"

import ChildButton from "./Button"

import Colors from "../../../const/Colors"
import StylesReader from "../../../hoc/StylesReader"

const Button = ({
  primary,
  primaryDark,
  accent,
  black,
  error,
  darkText,
  fullWidth,
  neutral,
  // marginHalf,
  // margin,
  style: parentStyles,
  enabled,
  ...props
}) => {
  let styles = {
    backgroundColor: Colors.neutral,
    borderColor: "transparent",
    borderWidth: 0,
    // borderRadius: 7.5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minHeight: 40,
    justifyContent: "center",
    alignItems: "center",
  }

  if (primary) styles.backgroundColor = Colors.primary
  else if (primaryDark) styles.backgroundColor = Colors.primaryDark
  else if (accent) styles.backgroundColor = Colors.accent
  else if (neutral) styles.backgroundColor = Colors.neutral
  else if (error) styles.backgroundColor = Colors.error
  else if (black) styles.backgroundColor = Colors.black

  // if (margin) styles.margin = 10
  // else if (marginHalf) styles.margin = 5

  if (fullWidth) {
    styles.flex = 1
    styles.flexDirection = "row"
    styles.alignItems = "center"
    styles.justifyContent = "center"
  }

  if (!enabled) {
    styles.backgroundColor = Colors.disabledBackground
    styles.color = Colors.disabledForeground
  }

  const style = Array.isArray(parentStyles) ? [styles].concat(parentStyles) : [styles, parentStyles]

  return <ChildButton {...props} style={style} {...{ enabled, darkText }} />
}

Button.defaultProps = {
  enabled: true,
}

export default StylesReader(Button)
