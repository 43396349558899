import React from "react"
import "./index.css"

const LoadingSpinner = (props) => (
  <div className="cssload-container">
    <div className="cssload-loading">
      <i></i>
      <i></i>
    </div>
  </div>
)

export default LoadingSpinner
