import React from "react"

import SectionTitle from "../SectionTitle"
import PlaceMultiSelector from "../PlaceMultiSelector"
import AdminSelector from "../AdminSelector"

import Card from "../primitives/Card"
import Layout from "../primitives/Layout"
import Text from "../primitives/Text"
import Switch from "../primitives/Switch"
import isMobile from "../primitives/isMobile"
import Button from "../primitives/Button"
import ImageUploader from "../ImageUploader"
import {ICON_SIZE} from "../Map/const"
import {PADDING_HALF} from "../../const/Dimensions"
import Assets from "../../const/Assets"

const TagAdminRender = ({
                          isMobile,
                          places,
                          markers,
                          selectedTag,
                          addPlaceTitle,
                          visibility,
                          isSponsored,
                          sponsoredUrl,
                          valueEditors,
                          orderPosition,
                          handleChangePosition,
                          handleChangeEditor,
                          handleDeleteTag,
                          handleChangeSponsoredUrl,
                          onValueChangeVisibility,
                          onValueChangeIsSponsored,
                          ...props
                        }) => {
  if (!selectedTag) return null
  return (
    <Card margin={isMobile} bottomSpace={!isMobile} topSpace={!isMobile} paddingHalf {...props}>
      <Text medium bold accent paddingHalf>{`Tag`}</Text>
      <Switch
        paddingHalf
        value={visibility}
        onValueChange={onValueChangeVisibility}
        labelEnabled="This tag is public and visible to everyone"
        labelDisabled="This tag is not visible"
      />
      <Text small soft paddingHalf>
        {markers.length} places
      </Text>
      <SectionTitle>{addPlaceTitle}</SectionTitle>
      <PlaceMultiSelector marginHalf title={addPlaceTitle} {...{places, selectedTag}} />
      <Layout>
        <SectionTitle>Assigned to</SectionTitle>
        <AdminSelector
          style={{flex: 1}}
          marginHalf
          value={valueEditors}
          onChange={handleChangeEditor}
        />
        <SectionTitle>Order position</SectionTitle>
        <Text
          small
          editable
          marginHalf
          singleLine
          editKey={selectedTag.id}
          onTextEdited={handleChangePosition}
          placeholder="Write a position number to re-order the list"
        >
          {orderPosition}
        </Text>
        <SectionTitle>Map Icon</SectionTitle>
        <ImageUploader
          refUrl={`tags/zecelarece/${selectedTag.id}/icon`}
          fileRefUrl={`image/zecelarece/icon/${selectedTag.id}.jpg`}
          style={{height: ICON_SIZE.default, width: ICON_SIZE.default, margin: PADDING_HALF / 2}}
          defaultImage={Assets.IMG_MARKER_PLACE_VISIBLE}
        />
        {isSponsored && (
          <>
            <SectionTitle>Sponsored URL</SectionTitle>
            <Text
              small
              editable
              paddingHalf
              singleLine
              editKey={selectedTag.id}
              onTextEdited={handleChangeSponsoredUrl}
              placeholder="Add the url of the sponsored campaign"
            >
              {sponsoredUrl}
            </Text>
          </>
        )}
        <Switch
          paddingHalf
          value={isSponsored}
          onValueChange={onValueChangeIsSponsored}
          labelEnabled="This tag represents a sponsored campaign"
          labelDisabled="This tag is not a sponsored campaign"
        />
        <Button accent fullWidth topSpace onPress={handleDeleteTag}>
          {`Delete this tag`}
        </Button>
        {/* todo: <Button accent bottomSpace={!isMobile}>Delete this tag</Button> */}
      </Layout>
    </Card>
  )
}

export default isMobile(TagAdminRender)
