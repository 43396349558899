class CacheUtils {
  static setItem = (key, value) => {
    if (typeof Storage !== "undefined") {
      localStorage.setItem(`${process.env.REACT_APP_APP_NAME}_${key}`, value)
    } else {
      // Sorry! No Web Storage support..
    }
  }

  static getItem = (key) => {
    if (typeof Storage !== "undefined") {
      return localStorage.getItem(`${process.env.REACT_APP_APP_NAME}_${key}`)
    } else {
      return null
      // Sorry! No Web Storage support..
    }
  }

  static removeItem = (key) => {
    if (typeof Storage !== "undefined") {
      localStorage.removeItem(`${process.env.REACT_APP_APP_NAME}_${key}`)
    } else {
      // Sorry! No Web Storage support..
    }
  }

  static saveState = (key, state) => {
    CacheUtils.setItem(key, JSON.stringify(state))
  }

  static getState = (key) => {
    const stateString = CacheUtils.getItem(key)
    return stateString ? JSON.parse(stateString) : null
  }
}

export default CacheUtils
